export const standardEcieldKeys = {
    standardEcieldKeys: [
        "ecield_event_ec_settle_limit_price",
        "ecield_event_ec_settle_status",
        "ecield_event_ec_settle_datetime",
        "ecield_event_ec_settle_amount",
        "ecield_event_ec_settle_method",
        "ecield_event_ec_settle_credit_card_number_hash",
        "ecield_event_ec_settle_credit_card_bincode",
        "ecield_event_ec_settle_credit_card_authori_info_authori_code",
        "ecield_event_ec_settle_credit_card_issuer_foreign_flg",
        "ecield_event_customers_buyer_ID",
        "ecield_event_customers_buyer_birth_day",
        "ecield_event_customers_buyer_sex",
        "ecield_event_customers_buyer_name_first_name",
        "ecield_event_customers_buyer_name_last_name",
        "ecield_event_customers_buyer_name_first_name_kana",
        "ecield_event_customers_buyer_name_last_name_kana",
        "ecield_event_customers_buyer_address_zipcode",
        "ecield_event_customers_buyer_address_address_a",
        "ecield_event_customers_buyer_address_address_b",
        "ecield_event_customers_buyer_address_address_c",
        "ecield_event_customers_buyer_address_address_d",
        "ecield_event_customers_buyer_tel_fixed_number",
        "ecield_event_customers_buyer_tel_mobile_number",
        "ecield_event_customers_buyer_pc_account",
        "ecield_event_customers_buyer_pc_domain",
        "ecield_event_customers_buyer_mobile_account",
        "ecield_event_customers_buyer_mobile_domain",
        "ecield_event_customers_buyer_name_company_name",
        "ecield_event_customers_buyer_name_company_department",
        "ecield_event_customers_buyer_name_company_post",
        "ecield_event_customers_buyer_name_existing_customer_flg",
        "ecield_event_customers_tenant_shop_tenant_id",
        "ecield_event_customers_tenant_tenant_limit_price",
        "ecield_event_customers_tenant_member_limit_change_ratio",
        "ecield_event_customers_tenant_tenant_name",
        "ecield_event_customers_tenant_tenant_url",
        "ecield_event_customers_tenant_tenant_registration_date",
        "ecield_event_customers_tenant_site_id",
        "ecield_event_customers_tenant_site_pass"
    ] as const,
    deliveryEcieldKeys: [
        "ecield_event_customers_deliveries_id",
        "ecield_event_customers_deliveries_birth_day",
        "ecield_event_customers_deliveries_sex",
        "ecield_event_customers_deliveries_specified_deliver_datetime",
        "ecield_event_customers_deliveries_delivery_specified_existence",
        "ecield_event_customers_deliveries_delivery_frequency",
        "ecield_event_customers_deliveries_delivery_company_name",
        "ecield_event_customers_deliveries_name_first_name",
        "ecield_event_customers_deliveries_name_last_name",
        "ecield_event_customers_deliveries_address_zipcode",
        "ecield_event_customers_deliveries_address_address_a",
        "ecield_event_customers_deliveries_address_address_b",
        "ecield_event_customers_deliveries_address_address_c",
        "ecield_event_customers_deliveries_address_address_d",
        "ecield_event_customers_deliveries_tel_fixed_number",
        "ecield_event_customers_deliveries_tel_mobile_number",
        "ecield_event_customers_deliveries_email_pc_account",
        "ecield_event_customers_deliveries_email_pc_domain",
        "ecield_event_customers_deliveries_email_mobile_account",
        "ecield_event_customers_deliveries_email_mobile_domain",
        "ecield_event_customers_deliveries_company_name",
        "ecield_event_customers_deliveries_company_department",
        "ecield_event_customers_deliveries_company_post"
    ],
    itemEcieldKeys: [
        "ecield_event_customers_items_shop_item_id",
        "ecield_event_customers_items_item_price",
        "ecield_event_customers_items_item_quantity",
        "ecield_event_customers_items_item_name",
        "ecield_event_customers_items_item_category",
        "ecield_event_customers_items_item_stock_flg"
    ],
    shopMainKeys: {
        ecield_shop_id: "ecield_shop_id",
        ecield_cart_system: "ecield_cart_system",
        ecield_cart_system_id: "ecield_cart_system_id"
    }
};
