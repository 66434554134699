import { IdTemplateSdkResponseType } from "types/type/dto/info-id";
import {
    LocalStorageSchema,
    createLocalStorageHandlers
} from "./localstorageHandler";
import { isBoolean, isNumber, isString } from "class-validator";
import {
    isIdTemplateSdkResponseType,
    isTimestampData,
    isChangeStatusDataObject,
    isIpBlockDataObject,
    testOrderObject
} from "./typeCheckFunction";

/**
 * タイムスタンプ付きのデータの定義
 */
export type TimestampData<T> = {
    timestamp: number;
    data: T;
};

/**
 * 出荷停止に必要なデータの型
 */
export type ChangeStatusData = {
    /** 顧客id（カート発行のもの、ない場合はサーバーでランダムに付与） */
    customerId: string;
    /** 審査に対して一意なid（bigintに変換可能な値） */
    eventId: string;
    /** 出荷停止処理をするかどうか（する場合はtrue） */
    isStatusChangeRequired: boolean;
    /** LPワンクリックかどうか */
    isUseLpOneclick: boolean;
    /** モーダルが表示済みかどうか（未表示の場合はtrue） */
    isModalDisplayRequired: boolean;
    /** モーダルのメッセージ */
    message?: string;
    /** モーダルのタイトル */
    title?: string;
};

/**
 * テスト注文に必要なデータの型
 */
export type TestOrder = {
    queryParameter: string;
};

/**
 * ipブロック関連のデータ型
 */
export type IpBlockData = {
    /** ブロック理由 */
    message: string;
    /** モーダル表示用データ */
    modal: {
        title: string;
        message: string;
        redirectUrl: string;
    };
    /** ipブロックが発生した時間（ISO 8601日時の文字列 */
    blockTime: string;
    /** ipブロックが解除される時間（ISO 8601日時の文字列 */
    unblockTime: string;
};

/**
 *localStorageのスキーマの型定義
 */
type Schema = {
    /** 情報収集id */
    idTemplateSdkResponse: IdTemplateSdkResponseType | null;
    /** 最終取得時間 */
    latestFetchTimestamp: number | null;
    /** 受注ステータス変更に必要なデータ */
    changeStatusData: TimestampData<ChangeStatusData>;
    /** ipブロック関連のデータ */
    ipBlock: IpBlockData | null;
    testOrder: TimestampData<TestOrder> | null;
    /** rollback_and_submitが実行されたかどうか */
    isRollbackAndSubmitExecuted: TimestampData<boolean> | null;
    /** 利用中の画面(front, lp, lp-oneclick) */
    useScreenType: TimestampData<string> | null;
};

/**
 * localStorageの実際の値を設定する
 * デフォルト値と型チェック関数をペアで指定
 */
const schema: LocalStorageSchema<Schema> = {
    // 下記2つは使用例です
    idTemplateSdkResponse: {
        defaultValue: null,
        typeCheckFunction: isIdTemplateSdkResponseType
    },
    latestFetchTimestamp: {
        defaultValue: null,
        typeCheckFunction: isNumber
    },
    changeStatusData: {
        defaultValue: {
            timestamp: 0,
            data: {
                customerId: null,
                isStatusChangeRequired: null,
                isModalDisplayRequired: null,
                isUseLpOneclick: null,
                title: "",
                message: "",
                eventId: "0n"
            }
        },
        typeCheckFunction: isTimestampData(isChangeStatusDataObject)
    },
    ipBlock: {
        defaultValue: null,
        typeCheckFunction: isIpBlockDataObject
    },
    testOrder: {
        defaultValue: null,
        typeCheckFunction: isTimestampData(testOrderObject)
    },
    isRollbackAndSubmitExecuted: {
        defaultValue: null,
        typeCheckFunction: isTimestampData(isBoolean)
    },
    useScreenType: {
        defaultValue: null,
        typeCheckFunction: isTimestampData(isString)
    }
};

export const localStorageHandlers = createLocalStorageHandlers(schema);
