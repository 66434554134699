export const ecieldDefaultIds = {
    ids: {
        ecield_event_customers_deliveries_id:
            "shop_event_customers_deliveries_id",
        ecield_event_customers_deliveries_birth_day:
            "shop_event_customers_deliveries_birth_day",
        ecield_event_customers_deliveries_sex:
            "shop_event_customers_deliveries_sex",
        ecield_event_customers_deliveries_specified_deliver_datetime:
            "shop_event_customers_deliveries_specified_deliver_datetime",
        ecield_event_customers_deliveries_delivery_specified_existence:
            "shop_event_customers_deliveries_delivery_specified_existence",
        ecield_event_customers_deliveries_delivery_frequency:
            "shop_event_customers_deliveries_delivery_frequency",
        ecield_event_customers_deliveries_delivery_company_name:
            "shop_event_customers_deliveries_delivery_company_name",
        ecield_event_customers_deliveries_name_first_name:
            "shop_event_customers_deliveries_name_first_name",
        ecield_event_customers_deliveries_name_last_name:
            "shop_event_customers_deliveries_name_last_name",
        ecield_event_customers_deliveries_address_zipcode:
            "shop_event_customers_deliveries_address_zipcode",
        ecield_event_customers_deliveries_address_address_a:
            "shop_event_customers_deliveries_address_address_a",
        ecield_event_customers_deliveries_address_address_b:
            "shop_event_customers_deliveries_address_address_b",
        ecield_event_customers_deliveries_address_address_c:
            "shop_event_customers_deliveries_address_address_c",
        ecield_event_customers_deliveries_address_address_d:
            "shop_event_customers_deliveries_address_address_d",
        ecield_event_customers_deliveries_tel_fixed_number:
            "shop_event_customers_deliveries_tel_fixed_number",
        ecield_event_customers_deliveries_tel_mobile_number:
            "shop_event_customers_deliveries_tel_mobile_number",
        ecield_event_customers_deliveries_email_pc_account:
            "shop_event_customers_deliveries_email_pc_account",
        ecield_event_customers_deliveries_email_pc_domain:
            "shop_event_customers_deliveries_email_pc_domain",
        ecield_event_customers_deliveries_email_mobile_account:
            "shop_event_customers_deliveries_email_mobile_account",
        ecield_event_customers_deliveries_email_mobile_domain:
            "shop_event_customers_deliveries_email_mobile_domain",
        ecield_event_customers_deliveries_company_name:
            "shop_event_customers_deliveries_company_name",
        ecield_event_customers_deliveries_company_department:
            "shop_event_customers_deliveries_company_department",
        ecield_event_customers_deliveries_company_post:
            "shop_event_customers_deliveries_company_post",
        ecield_event_customers_items_shop_item_id:
            "shop_event_customers_items_shop_item_id",
        ecield_event_customers_items_item_price:
            "shop_event_customers_items_item_price",
        ecield_event_customers_items_item_quantity:
            "shop_event_customers_items_item_quantity",
        ecield_event_customers_items_item_name:
            "shop_event_customers_items_item_name",
        ecield_event_customers_items_item_category:
            "shop_event_customers_items_item_category",
        ecield_event_customers_items_item_stock_flg:
            "shop_event_customers_items_item_stock_flg",
        ecield_shop_id: "default_ecield_shop_id",
        ecield_cart_system: "default_ecield_cart_system",
        ecield_cart_system_id: "default_ecield_cart_system_id",
        ecield_event_ec_settle_limit_price: "shop_event_ec_settle_limit_price",
        ecield_event_ec_settle_status: "shop_event_ec_settle_status",
        ecield_event_ec_settle_datetime: "shop_event_ec_settle_datetime",
        ecield_event_ec_settle_amount: "shop_event_ec_settle_amount",
        ecield_event_ec_settle_method: "shop_event_ec_settle_method",
        ecield_event_ec_settle_credit_card_number_hash:
            "shop_event_ec_settle_credit_card_number_hash",
        ecield_event_ec_settle_credit_card_bincode:
            "shop_event_ec_settle_credit_card_bincode",
        ecield_event_ec_settle_credit_card_authori_info_authori_code:
            "shop_event_ec_settle_credit_card_authori_info_authori_code",
        ecield_event_ec_settle_credit_card_issuer_foreign_flg:
            "shop_event_ec_settle_credit_card_issuer_foreign_flg",
        ecield_event_customers_buyer_ID: "shop_event_customers_buyer_ID",
        ecield_event_customers_buyer_birth_day:
            "shop_event_customers_buyer_birth_day",
        ecield_event_customers_buyer_sex: "shop_event_customers_buyer_sex",
        ecield_event_customers_buyer_name_first_name:
            "shop_event_customers_buyer_name_first_name",
        ecield_event_customers_buyer_name_last_name:
            "shop_event_customers_buyer_name_last_name",
        ecield_event_customers_buyer_name_first_name_kana:
            "shop_event_customers_buyer_name_first_name_kana",
        ecield_event_customers_buyer_name_last_name_kana:
            "shop_event_customers_buyer_name_last_name_kana",
        ecield_event_customers_buyer_address_zipcode:
            "shop_event_customers_buyer_address_zipcode",
        ecield_event_customers_buyer_address_address_a:
            "shop_event_customers_buyer_address_address_a",
        ecield_event_customers_buyer_address_address_b:
            "shop_event_customers_buyer_address_address_b",
        ecield_event_customers_buyer_address_address_c:
            "shop_event_customers_buyer_address_address_c",
        ecield_event_customers_buyer_address_address_d:
            "shop_event_customers_buyer_address_address_d",
        ecield_event_customers_buyer_tel_fixed_number:
            "shop_event_customers_buyer_tel_fixed_number",
        ecield_event_customers_buyer_tel_mobile_number:
            "shop_event_customers_buyer_tel_mobile_number",
        ecield_event_customers_buyer_pc_account:
            "shop_event_customers_buyer_pc_account",
        ecield_event_customers_buyer_pc_domain:
            "shop_event_customers_buyer_pc_domain",
        ecield_event_customers_buyer_mobile_account:
            "shop_event_customers_buyer_mobile_account",
        ecield_event_customers_buyer_mobile_domain:
            "shop_event_customers_buyer_mobile_domain",
        ecield_event_customers_buyer_name_company_name:
            "shop_event_customers_buyer_company_name",
        ecield_event_customers_buyer_name_company_department:
            "shop_event_customers_buyer_company_department",
        ecield_event_customers_buyer_name_company_post:
            "shop_event_customers_buyer_company_post",
        ecield_event_customers_buyer_name_existing_customer_flg:
            "shop_event_customers_buyer_existing_customer_flg",
        ecield_event_customers_tenant_shop_tenant_id:
            "shop_event_customers_tenant_shop_tenant_id",
        ecield_event_customers_tenant_tenant_limit_price:
            "shop_event_customers_tenant_tenant_limit_price",
        ecield_event_customers_tenant_member_limit_change_ratio:
            "shop_event_customers_tenant_member_limit_change_ratio",
        ecield_event_customers_tenant_tenant_name:
            "shop_event_customers_tenant_tenant_name",
        ecield_event_customers_tenant_tenant_url:
            "shop_event_customers_tenant_tenant_url",
        ecield_event_customers_tenant_tenant_registration_date:
            "shop_event_customers_tenant_tenant_registration_date",
        ecield_event_customers_tenant_site_id:
            "shop_event_customers_tenant_site_id",
        ecield_event_customers_tenant_site_pass:
            "shop_event_customers_tenant_site_pass"
    },

    validateValuePaths: {
        ecield_shop_id: "info.shop_id",
        ecield_cart_system: "info.cart_system",
        ecield_cart_system_id: "info.cart_system_id",
        ecield_event_ec_settle_limit_price: "event.ec.settle.limit_price",
        ecield_event_ec_settle_status: "event.ec.settle.status",
        ecield_event_ec_settle_datetime: "event.ec.settle.datetime",
        ecield_event_ec_settle_amount: "event.ec.settle.amount",
        ecield_event_ec_settle_method: "event.ec.settle.method",
        ecield_event_ec_settle_credit_card_number_hash:
            "event.ec.settle.credit_card.number_hash",
        ecield_event_ec_settle_credit_card_bincode:
            "event.ec.settle.credit_card.bincode",
        ecield_event_ec_settle_credit_card_authori_info_authori_code:
            "event.ec.settle.credit_card.authori_info.authori_code",
        ecield_event_ec_settle_credit_card_issuer_foreign_flg:
            "event.ec.settle.credit_card.issuer_foreign_flg",
        ecield_event_customers_buyer_ID: "event.customers.buyer.id",
        ecield_event_customers_buyer_birth_day:
            "event.customers.buyer.birth_day",
        ecield_event_customers_buyer_sex: "event.customers.buyer.sex",
        ecield_event_customers_buyer_name_first_name:
            "event.customers.buyer.name.first_name",
        ecield_event_customers_buyer_name_last_name:
            "event.customers.buyer.name.last_name",
        ecield_event_customers_buyer_name_first_name_kana:
            "event.customers.buyer.name.first_name_kana",
        ecield_event_customers_buyer_name_last_name_kana:
            "event.customers.buyer.name.last_name_kana",
        ecield_event_customers_buyer_address_zipcode:
            "event.customers.buyer.address.zipcode",
        ecield_event_customers_buyer_address_address_a:
            "event.customers.buyer.address.addressA",
        ecield_event_customers_buyer_address_address_b:
            "event.customers.buyer.address.addressB",
        ecield_event_customers_buyer_address_address_c:
            "event.customers.buyer.address.addressC",
        ecield_event_customers_buyer_address_address_d:
            "event.customers.buyer.address.addressD",
        ecield_event_customers_buyer_tel_fixed_number:
            "event.customers.buyer.tel.fixed_number",
        ecield_event_customers_buyer_tel_mobile_number:
            "event.customers.buyer.tel.mobile_number",
        ecield_event_customers_buyer_pc_account:
            "event.customers.buyer.email.pc.account",
        ecield_event_customers_buyer_pc_domain:
            "event.customers.buyer.email.pc.domain",
        ecield_event_customers_buyer_mobile_account:
            "event.customers.buyer.email.mobile.account",
        ecield_event_customers_buyer_mobile_domain:
            "event.customers.buyer.email.mobile.domain",
        ecield_event_customers_buyer_name_company_name:
            "event.customers.buyer.company.name",
        ecield_event_customers_buyer_name_company_department:
            "event.customers.buyer.company.department",
        ecield_event_customers_buyer_name_company_post:
            "event.customers.buyer.company.post",
        ecield_event_customers_buyer_name_existing_customer_flg:
            "event.customers.buyer.existing_customer_flg",
        ecield_event_customers_tenant_shop_tenant_id:
            "event.tenant.shop_tenant_id",
        ecield_event_customers_tenant_tenant_limit_price:
            "event.tenant.tenant_limit_price",
        ecield_event_customers_tenant_member_limit_change_ratio:
            "event.tenant.member_limit_change_ratio",
        ecield_event_customers_tenant_tenant_name: "event.tenant.tenant_name",
        ecield_event_customers_tenant_tenant_url: "event.tenant.tenant_url",
        ecield_event_customers_tenant_tenant_registration_date:
            "event.tenant.tenant_registration_date",
        ecield_event_customers_tenant_site_id: "event.tenant.site_id",
        ecield_event_customers_tenant_site_pass: "event.tenant.site_pass"
    }
};
