export const ecieldUrls = {
    ecieldShopCustomizeIdUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/info/id-template`,
    ecieldVerifyDataUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/examinations`,
    ecieldExaminationLastDataUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/session/examination-last-data`,
    ecieldExaminationLatestResultUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/session/examination-last-result`,
    ecieldVerifyUserUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/authenticate/verify-user`,
    ecieldChangeStatusUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/queue/job`,
    ecieldSendOrderUidUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/queue/order-uid`,
    ecieldCreateLogUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/logs`,
    ecieldCompleteLogUrl: `${process.env.SERVER_PROTOCOL}://${process.env.SERVER_DOMAIN}/api/logs/complete`,
    cartDeleteItemForEggUrl: (cartDomain: string) => {
        return `${process.env.SERVER_PROTOCOL}://${cartDomain}/shop/delete_item`;
    },
    cartDeleteItemForSubscUrl: (cartDomain: string) => {
        return `${process.env.SERVER_PROTOCOL}://${cartDomain}/api/cart`;
    },

    fraudBusterUrl: "https://fraud-buster.appspot.com/js/fraudbuster.js"
};
