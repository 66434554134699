import { EcieldConstants } from "../constants";
import { EcieldConfig } from "../types";

/**
 * loadFraudBusterScript - FraudBusterスクリプトをロードし、ドキュメントに非表示の入力要素を追加します。
 */
function loadFraudBusterScript(): void {
    const fraudBusterScript = document.createElement("script");
    fraudBusterScript.src = EcieldConstants.fraudBusterUrl;
    document.head.appendChild(fraudBusterScript);

    const fraudBusterInputElement = document.createElement("input");
    fraudBusterInputElement.type = "hidden";
    fraudBusterInputElement.id = "fraudbuster";

    const parentElement = document.body;
    parentElement.appendChild(fraudBusterInputElement);
}

/**
 * 二つのオブジェクトをマージ。第二のオブジェクトは部分的なオブジェクトであり、
 * 第一のオブジェクトに存在するプロパティのみを上書。
 * 第二のオブジェクトがnullまたはundefinedの場合、第一のオブジェクトのコピーが返されます。
 *
 * @param obj1 - マージする最初のオブジェクト
 * @param obj2 - マージする第二の部分的なオブジェクト
 * @return マージされた新しいオブジェクト
 */
function mergeInitializationValues<T extends object>(
    obj1: T,
    obj2: Partial<T>
): T {
    if (!obj2) {
        return { ...obj1 };
    }

    return {
        ...obj1,
        ...Object.fromEntries(
            Object.entries(obj2).filter(
                ([key, value]) =>
                    obj1.hasOwnProperty(key) &&
                    value !== null &&
                    value !== undefined &&
                    value !== ""
            )
        )
    };
}

function getButtonIdentifiersForScreenType(
    systemScreenType: string | null
): string[] {
    switch (systemScreenType) {
        case EcieldConstants.eggCartSoulLpConfirmFlag: {
            return [
                EcieldConstants.eggCartSoulLpConfirmDirectSubmitButtonName,
                EcieldConstants.eggCartSoulLpConfirmAlwaysDisplaySubmitButtonId
            ];
        }
        default: {
            return [];
        }
    }
}

/**
 * 現在のEcield設定と追加の設定を組み合わせて、新しいEcield設定を生成する関数です。
 * 指定されたcartSystemTypeに基づいて、設定の組み合わせ方法が変わります。
 *
 * @param currentConfig - 現在のEcield設定
 * @param additionalConfig - 追加される設定の部分集合
 * @param cartSystemType - カートシステムの種類（null可）
 * @return 組み合わせた後の新しいEcield設定
 */
function combineEcieldConfigurations(
    ecieldConfig: EcieldConfig,
    newEcieldConfig: Partial<EcieldConfig>,
    cart_system: string | null
): EcieldConfig {
    const mergedConfig: EcieldConfig = { ...ecieldConfig };

    if (!newEcieldConfig) {
        return mergedConfig;
    }

    const booleanProperties = ["isSubmitButtonDisabledUntilExamination"];
    const stringProperties = ["useScreen", "systemScreenType"];

    booleanProperties.forEach(property => {
        if (
            property in newEcieldConfig &&
            typeof newEcieldConfig[property] === "boolean"
        ) {
            mergedConfig[property] = newEcieldConfig[property];
        }
    });

    stringProperties.forEach(property => {
        if (
            property in newEcieldConfig &&
            typeof newEcieldConfig[property] === "string"
        ) {
            mergedConfig[property] = newEcieldConfig[property];
        }
    });

    if (
        "submitButtonIds" in newEcieldConfig &&
        Array.isArray(newEcieldConfig.submitButtonIds)
    ) {
        mergedConfig.submitButtonIds = newEcieldConfig.submitButtonIds;
    } else {
        mergedConfig.submitButtonIds = [];
    }

    if (cart_system.includes("subsc")) {
        mergedConfig.systemScreenType = `subsc-${mergedConfig.useScreen || ""}`;
    } else {
        mergedConfig.systemScreenType = `${cart_system || ""}-${
            mergedConfig.useScreen || ""
        }`;
    }

    // 特定の画面タイプに基づいて追加されるボタンの識別子をsubmitButtonIds配列に追加します。
    mergedConfig.submitButtonIds.push(
        ...getButtonIdentifiersForScreenType(mergedConfig.systemScreenType)
    );

    return mergedConfig;
}

export {
    loadFraudBusterScript,
    combineEcieldConfigurations,
    mergeInitializationValues,
    getButtonIdentifiersForScreenType
};
