export const specialConstant = {
    eggCartLpUserEmailName: "user[email]",
    eggCartFrontUserEmailName: "user[email]",
    eggCartFrontUserEmailId: "user_email",
    eggCartFrontUserBirthday: "user[birthday_i]",
    eggCartManagementShippingTel: "shipping_address[tel]",
    eggCartManagementShippingSubTel: "shipping_address[sub_tel]",
    eggCartManagementDeliveryTel: "order[delivery_tel]",
    eggCartManagementDeliverySubTel: "order[sub_delivery_tel]",
    eggCartManagementUserBirthday: "user[birthday_i]",
    eggCartLpShippingTel: "shipping_address[tel]",
    eggCartLpShippingSubTel: "shipping_address[sub_tel]",
    eggCartLpUserBirthday: "user[birthday_i]",
    eggCartLpUserEmail: "user[email]"
};
