import { InitEcieldValues } from "../types";
import { localStorageHandlers } from "./local_storage/localStorageSchema";

const EXPIRATION_TIME = 3 * 60 * 1000; // 3分をミリ秒に変換

/**
 * クエリパラメータを取得する
 * @returns String型のクエリパラメータ
 */
function handleTestQueryParams() {
    return new URLSearchParams(window.location.search).toString();
}

/**
 *  ローカルストレージから古いデータを削除する
 */
function cleanExpiredData() {
    const testOrderData = localStorageHandlers.testOrder.getValue();
    if (
        testOrderData &&
        testOrderData.timestamp < Date.now() - EXPIRATION_TIME
    ) {
        localStorageHandlers.testOrder.remove();
    }
}

/**
 * ローカルストレージにクエリパラメータを保存する
 * @param values
 */
export function saveTestQueryParams(values: InitEcieldValues) {
    const currentTime = new Date().getTime();

    if (values.shopInfo.cart_system.includes("subsc")) {
        const pathsToCheck = ["/checkout/register", "/cart"];
        if (pathsToCheck.includes(window.location.pathname)) {
            const queryParams = handleTestQueryParams();

            localStorageHandlers.testOrder.setValue({
                timestamp: currentTime,
                data: {
                    queryParameter: queryParams
                }
            });
        }
    }
}

/**
 * クエリパラメータを取得し返す（ローカルストレージ含む）
 * 必要に応じて "?" を先頭に付けて返す
 * @returns String型のクエリパラメータ
 */
export function getTestQueryParams(): string {
    let queryParams = "";

    if (window.location.pathname === "/checkout/confirm") {
        cleanExpiredData();

        const testOrderData = localStorageHandlers.testOrder.getValue();

        if (testOrderData && testOrderData.data) {
            queryParams = testOrderData.data.queryParameter;
        }
    } else {
        queryParams = handleTestQueryParams();
    }

    // queryParamsが空でない場合に "?" を先頭に付ける
    return queryParams ? `?${queryParams}` : "";
}
