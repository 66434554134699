import { ExaminationData } from "../types";
import { EcieldConstants } from "../constants";
import { ExaminationDto } from "types/class/dto/examination.dto";
import { getValueByPath } from "./helpers";

/**
 * ExaminationDataを検証し、必要なIDが存在するかどうかをチェックする関数です。
 * shop_id, cart_system, cart_system_idの情報がない場合や、
 * 必要なIDに対応する値が文字列でない場合にエラーを返します。
 *
 * @param data - 検証するExaminationDataオブジェクト
 * @param requiredIds - 検証が必要なIDの配列
 * @return エラーの配列。エラーがない場合は空の配列を返します。
 */
function validateData(data: ExaminationData, requiredIds: string[]): string[] {
    const errors: string[] = [];
    const {
        shop_id: shopId,
        cart_system: cartSystem,
        cart_system_id: cartSystemId
    } = data.info || {};

    console.log("requiredIdrequiredId", requiredIds);

    if (!shopId && (!cartSystem || !cartSystemId)) {
        errors.push("no information about the shop");
    }

    for (const requiredId of requiredIds) {
        const path = EcieldConstants.validateValuePaths[requiredId];
        if (path && typeof getValueByPath(data, path) !== "string") {
            errors.push(`no ${requiredId}`);
        }
    }

    return errors;
}

// コンテンツが存在するか確認
function isContentless(value: unknown): boolean {
    return value === null || value === undefined || value === "";
}

/**
 * 引数がresponseおよびstatusを保持しているかを判定るる型ガード
 * @param error 判定するオブジェクト
 * @returns obj.response.statusへアクセスできるかどうか
 */
function isAxiosErrorWithResponseStatus(
    error: unknown
): error is { response: { status: number } } {
    return (
        typeof error === "object" &&
        error !== null &&
        "response" in error &&
        typeof error.response === "object" &&
        error.response !== null &&
        "status" in error.response &&
        typeof error.response.status === "number"
    );
}

/**
 * EcieldConstants.standardEcieldIdsに含まれる文字列かどうかを判例する型ガード
 * @param string 判定する文字列
 * @returns 含まれる場合はtrue
 */
function isInStandardEcieldIds(
    string: string
): string is (typeof EcieldConstants.standardEcieldKeys)[number] {
    return EcieldConstants.standardEcieldKeys.includes(
        string as (typeof EcieldConstants.standardEcieldKeys)[number]
    );
}

/**
 * 二つのオブジェクトが等しいかどうかを確認します。
 * この関数は再帰的にオブジェクトのプロパティを比較し、
 * 同じプロパティ名が同じ値を持つかどうかを検証します。
 * ネストされたオブジェクトもサポートされています。
 * @param objA 比較する最初のオブジェクト
 * @param objB 比較する二番目のオブジェクト
 * @returns 等しい場合はtrue、そうでない場合はfalse
 */
function isObjectsEqual(objA: any, objB: any): boolean {
    if (objA === objB) {
        return true;
    }

    if (
        typeof objA !== "object" ||
        typeof objB !== "object" ||
        objA == null ||
        objB == null
    ) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (const key of keysA) {
        if (!Object.prototype.hasOwnProperty.call(objB, key)) {
            return false;
        }

        if (typeof objA[key] === "object" && typeof objB[key] === "object") {
            if (!isObjectsEqual(objA[key], objB[key])) {
                return false;
            }
        } else if (objA[key] !== objB[key]) {
            return false;
        }
    }

    return true;
}

/**
 * 受け取った型がExaminationDtoかを判定する
 * @param obj 判定したいオブジェクト
 * @returns ExaminationDtoかどうか
 */
function isExaminationDto(obj: unknown): obj is ExaminationDto {
    if (typeof obj !== "object" || obj === null) {
        return false;
    }

    const dto = obj as ExaminationDto;
    // info, event, event.customerがあればExaminationDtoとみなす
    return (
        "info" in dto &&
        "event" in dto &&
        !!dto.event &&
        "customers" in dto?.event
    );
}

// /**
//  * 配列ないのstringが第二引数の文字列の中に含まれるかどうか
//  * @param arr
//  * @param char
//  * @returns 配列ないの文字が一つでも含まれていた場合はtrue
//  */
// function isContainsCharacter(arr: string[], char: string): boolean {
//     return arr.some(element => char.includes(element));
// }

/**
 * 指定されたタイムスタンプからn時間が経過しているかどうかを判定する関数
 * @param timestamp 比較するタイムスタンプ（ミリ秒単位）
 * @param hours 経過すべき時間（時間単位）
 * @returns 指定された時間が経過していれば true、そうでなければ false
 */
export const isTimeElapsedHours = (
    timestamp: number,
    hours: number
): boolean => {
    const currentTime = Date.now();
    const elapsedMilliseconds = hours * 60 * 60 * 1000; // n時間をミリ秒に変換
    return currentTime - timestamp > elapsedMilliseconds;
};

/**
 * 指定されたタイムスタンプからn分が経過しているかどうかを判定する関数
 * @param timestamp 比較するタイムスタンプ（ミリ秒単位）
 * @param minutes 経過すべき時間（分単位）
 * @returns 指定された時間が経過していれば true、そうでなければ false
 */
export const isTimeElapsedInMinutes = (
    timestamp: number,
    minutes: number
): boolean => {
    const currentTime = Date.now();
    const elapsedMilliseconds = minutes * 60 * 1000; // n分をミリ秒に変換
    return currentTime - timestamp > elapsedMilliseconds;
};

/**
 * 指定されたタイムスタンプが現在の日付の深夜0時を過ぎているかどうかを判定する関数
 * @param timestamp - 比較するタイムスタンプ（ミリ秒単位）
 * @returns 深夜0時を過ぎていれば true、そうでなければ false
 */
export const isDateCrossed = (timestamp: number): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // 現在の日付の深夜0時に設定
    return timestamp < currentDate.getTime();
};

export {
    isContentless,
    validateData,
    isObjectsEqual,
    isExaminationDto,
    isAxiosErrorWithResponseStatus,
    isInStandardEcieldIds
};
