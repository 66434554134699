import { IdTemplateSdkResponseType } from "../../../types/type";
import {
    ChangeStatusData,
    IpBlockData,
    TestOrder,
    TimestampData
} from "./localStorageSchema";

export const isIdTemplateSdkResponseType = (
    obj: unknown
): obj is IdTemplateSdkResponseType => {
    const record = obj as IdTemplateSdkResponseType;
    if (typeof record !== "object" || record === null) return false;
    if (!("values" in record)) return false;

    const values = record.values;
    if (typeof values !== "object" || values === null) return false;

    return Object.keys(values).every(key => typeof values[key] === "string");
};

// timestamp の型チェックを行う関数
export const isTimestamp = (value: unknown): value is number => {
    return typeof value === "number";
};

/**
 * タイムスタンプ付きデータの型判定を行う高階関数
 * 下記のようなデータ構造のものの判定を行う
 *
 * ```ts
 * const obj = {
 *      data: T, // こっちはisDataValid引数に渡されるチェック関数で判定
 *      timestamp: number // こっちはこの関数内で判定
 * }
 * ```
 * @param isDataValid
 * @returns
 */
export const isTimestampData =
    <T>(isDataValid: (data: unknown) => data is T) =>
    (obj: unknown): obj is TimestampData<T> => {
        const record = obj as TimestampData<T>;

        return (
            record !== null &&
            typeof record === "object" &&
            isTimestamp(record.timestamp) &&
            isDataValid(record.data)
        );
    };

/**
 * 受注ステータス変更処理に必要なデータの型確認をする
 * @param data 判定するデータ
 * @returns ローカルストレージ内が期待通りの型の場合 `true`
 */
export const isChangeStatusDataObject = (
    data: unknown
): data is ChangeStatusData => {
    const record = data as ChangeStatusData;

    return (
        typeof record === "object" &&
        record !== null &&
        typeof record.customerId === "string" &&
        record.eventId &&
        typeof record.isStatusChangeRequired === "boolean" &&
        typeof record.isUseLpOneclick === "boolean" &&
        (record.message === undefined || typeof record.message === "string") &&
        (record.title === undefined || typeof record.title === "string")
    );
};

/**
 * ipブロック時の情報を保持するkeyの型確認をする
 * @param data 判定するデータ
 * @returns ローカルストレージ内が期待通りの場合は `true`
 */
export const isIpBlockDataObject = (data: unknown): data is IpBlockData => {
    const record = data as IpBlockData;

    return (
        typeof record === "object" &&
        record !== null &&
        typeof record.message === "string" &&
        typeof record.modal === "object" &&
        record.modal !== null &&
        typeof record.modal.title === "string" &&
        typeof record.modal.message === "string" &&
        typeof record.modal.redirectUrl === "string" &&
        // Dateに変換可能な文字列
        !isNaN(Date.parse(record.blockTime)) &&
        !isNaN(Date.parse(record.unblockTime))
    );
};

/**
 * テスト注文に必要なクエリパラメータの型確認をする
 * @param data 判定するデータ
 * @returns ローカルストレージ内が期待通りの場合は `true`
 */
export const testOrderObject = (data: unknown): data is TestOrder => {
    const record = data as TestOrder;

    return typeof record.queryParameter === "string";
};
