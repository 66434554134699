import { ExaminationResponse } from "types/class/dto/examination.dto";
import { verifyByEcield } from "../../ecield-sdk";
import { standardEcieldIdFieldTranslations } from "../../constants";
import { isInStandardEcieldIds } from "../../utils";

let isModalOpen = false;
let overlay: HTMLDivElement;

function createButtonContainer(): HTMLDivElement {
    const container = document.createElement("div");
    container.style.position = "fixed";
    container.style.zIndex = "99999";
    container.style.top = "10px";
    container.style.left = "10px";
    document.body.appendChild(container);
    return container;
}

function createIcon(): HTMLButtonElement {
    const button = document.createElement("button");
    const svgIcon = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
    );
    svgIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgIcon.setAttribute("height", "1em");
    svgIcon.setAttribute("viewBox", "0 0 512 512");

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute(
        "d",
        "M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm231-113.9L103.5 277.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L256 226.9l101.6 101.6c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L273 142.1c-9.4-9.4-24.6-9.4-34 0z"
    );

    svgIcon.appendChild(path);
    button.appendChild(svgIcon);

    button.style.position = "fixed";
    button.style.cursor = "pointer";
    button.style.backgroundColor = "transparent";
    button.style.border = "0px solid #000";
    button.style.padding = "5px 10px";
    button.style.borderRadius = "5px";

    svgIcon.style.fontSize = "20px";
    svgIcon.style.opacity = "0.1";

    svgIcon.addEventListener("mouseover", () => {
        svgIcon.style.opacity = "0.6";
    });

    svgIcon.addEventListener("mouseout", () => {
        svgIcon.style.opacity = "0.1";
    });

    return button;
}

function closeModal() {
    const modal: HTMLElement | null = document.querySelector(
        ".examination-result-modal"
    );

    if (modal) {
        modal.style.display = "none";
        isModalOpen = false;
        modal.parentNode?.removeChild(modal);
    }

    if (overlay) {
        overlay.remove();
    }
}

function createOverlay(): HTMLDivElement {
    const overlayElement = document.createElement("div");
    overlayElement.classList.add("overlay");
    overlayElement.style.position = "fixed";
    overlayElement.style.top = "0";
    overlayElement.style.left = "0";
    overlayElement.style.width = "100%";
    overlayElement.style.height = "100%";
    overlayElement.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    overlayElement.style.zIndex = "99998";
    document.body.appendChild(overlayElement);
    return overlayElement;
}

function createModal(content: string): HTMLDivElement {
    overlay = createOverlay();
    overlay.classList.add("ecield-overlay");
    overlay.addEventListener("click", () => {
        closeModal();
    });

    const modal = document.createElement("div");
    modal.classList.add("examination-result-modal");
    modal.style.position = "fixed";
    modal.style.top = "50%";
    modal.style.left = "50%";
    modal.style.transform = "translate(-50%, -50%)";
    modal.style.backgroundColor = "#f8f9fa";
    modal.style.zIndex = "99999";
    modal.style.padding = "10px 20px";
    modal.style.borderRadius = "5px";
    modal.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.5)";
    modal.style.display = "block";
    modal.style.maxWidth = "80%";
    modal.style.maxHeight = "80%";
    modal.style.minWidth = "500px";

    const closeButton = document.createElement("span");
    closeButton.innerHTML = "&times;";
    closeButton.classList.add("close");
    closeButton.style.position = "absolute";
    closeButton.style.top = "10px";
    closeButton.style.right = "10px";
    closeButton.style.fontSize = "24px";
    closeButton.style.cursor = "pointer";
    closeButton.style.color = "#007bff";

    closeButton.addEventListener("click", () => {
        closeModal();
    });

    const modalContent = document.createElement("div");

    const modalTitle = document.createElement("h2");
    modalTitle.classList.add("ecield-modal-title");
    modalTitle.textContent = "Examination Result";

    const modalText = document.createElement("p");
    modalText.textContent = content;
    modalText.style.marginBottom = "0";

    const modalButton = document.createElement("button");
    modalButton.textContent = "OK";
    modalButton.style.display = "block";
    modalButton.style.margin = "0 auto";
    modalButton.style.padding = "10px 20px";
    modalButton.style.backgroundColor = "#007bff";
    modalButton.style.color = "#fff";
    modalButton.style.border = "none";
    modalButton.style.borderRadius = "5px";
    modalButton.style.cursor = "pointer";
    modalButton.style.marginTop = "20px";

    modalButton.addEventListener("click", () => {
        closeModal();
    });

    modalContent.appendChild(modalTitle);
    modalContent.appendChild(modalText);
    modalContent.appendChild(modalButton);

    modal.appendChild(closeButton);
    modal.appendChild(modalContent);

    return modal;
}

function openModal(content: string) {
    if (!isModalOpen) {
        isModalOpen = true;
        const modal = createModal(content);
        document.body.appendChild(modal);
        modal.addEventListener("click", event => {
            if (event.target === modal) {
                closeModal();
            }
        });
    }
}

function createButton(): HTMLButtonElement {
    const button = document.createElement("button");
    const buttonText1 = document.createTextNode("Ecield");
    const lineBreak = document.createElement("br");
    const buttonText2 = document.createTextNode("検査");

    button.style.position = "fixed";
    button.style.cursor = "pointer";
    button.style.backgroundColor = "#007bff";
    button.style.color = "#fff";
    button.style.border = "1px solid #007bff";
    button.style.padding = "10px 10px";
    button.style.borderRadius = "5px";
    button.style.boxShadow = "0px 5px 10px rgba(0, 0, 0, 0.6)";

    button.appendChild(buttonText1);
    button.appendChild(lineBreak);
    button.appendChild(buttonText2);

    button.addEventListener("click", () => {
        void verifyByEcield();
    });

    button.addEventListener("mouseover", () => {
        button.style.backgroundColor = "#0056b3";
        button.style.border = "1px solid #0056b3";
    });

    button.addEventListener("mouseout", () => {
        button.style.backgroundColor = "#007bff";
        button.style.border = "1px solid #007bff";
    });

    return button;
}

function moveButton(button: HTMLElement, x: number, y: number) {
    button.style.left = `${x}px`;
    button.style.top = `${y}px`;
}

function updateButtonPositions(button1: HTMLElement, button2: HTMLElement) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const buttonWidth = button2.offsetWidth;

    const desiredButton1Y = windowHeight - 2 * button1.offsetHeight - 80;
    const desiredButton2Y = windowHeight - button1.offsetHeight - 80;

    if (desiredButton1Y >= 0 && desiredButton2Y >= 0) {
        button1.style.top = desiredButton1Y + "px";
        button2.style.top = desiredButton2Y + "px";
    } else {
        button2.style.top = "30px";
        button1.style.top = windowHeight - button1.offsetHeight - 30 + "px";
    }

    button1.style.left = windowWidth - button1.offsetWidth - 10 + "px";
    button2.style.left = windowWidth - buttonWidth - 10 + "px";
}

function initializeButtons() {
    const buttonContainer = createButtonContainer();
    const button1 = createIcon();
    const button2 = createButton();

    buttonContainer.appendChild(button1);
    buttonContainer.appendChild(button2);

    const windowWidth = window.innerWidth;
    const buttonWidth = button2.offsetWidth;

    button1.style.left = windowWidth - button1.offsetWidth - 10 + "px";
    button2.style.left = windowWidth - buttonWidth - 10 + "px";

    button2.style.top = window.innerHeight - button1.offsetHeight - 20 + "px";

    button1.style.top =
        window.innerHeight - 2 * button1.offsetHeight - 20 + "px";

    updateButtonPositions(button1, button2);

    window.addEventListener("resize", () => {
        updateButtonPositions(button1, button2);
    });

    let isDragging = false;
    let offsetY;

    button1.addEventListener("mousedown", e => {
        isDragging = true;
        const rect = button2.getBoundingClientRect();
        offsetY = e.clientY - rect.top;
    });

    document.addEventListener("mousemove", e => {
        if (isDragging) {
            const y = e.clientY - offsetY;
            moveButton(button2, button2.getBoundingClientRect().left, y);
            moveButton(
                button1,
                button1.getBoundingClientRect().left,
                y - button1.offsetHeight - 0
            );
        }
    });

    document.addEventListener("mouseup", () => {
        isDragging = false;
    });
}

/** CSSStyleDeclarationからlength...などのreadonlyを取り除いた型 */
type StyleKeys = Exclude<
    keyof CSSStyleDeclaration,
    | "length"
    | "parentRule"
    | "getPropertyPriority"
    | "getPropertyValue"
    | "item"
    | "removeProperty"
    | "setProperty"
    | number // CSSStyleDeclarationの数値インデックスシグネチャを除外
>;

/** StyleKeysをkeyに持つオブジェクト */
type StyleObject = Partial<Record<StyleKeys, string | null>>;

/**
 * 要素にスタイルを適応する関数
 * @param element スタイルを適応したい要素
 * @param styles 適応するスタイル
 */
function applyStyles(element: HTMLElement, styles: StyleObject) {
    Object.entries(styles).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            (element.style as unknown)[key] = value;
        }
    });
}

/**
 * 結果表示ようコンテナ用のスタイルを適応する
 * @param container 結果表示のバックグラウンド部分に当たるコンテナ
 */
function setContainerStyles(container: HTMLElement, isNegativeResult: boolean) {
    const styles = {
        marginTop: "8px",
        marginBottom: "8px",
        padding: "16px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: isNegativeResult ? "#ffcccc" : "#f9f9f9" // NGの場合は背景を薄い赤色に
    };
    applyStyles(container, styles);
}

/**
 * 審査ボタンのスタイルを適応する
 * @param button ECieldで審査するボタン
 */
function setButtonStyles(button: HTMLButtonElement) {
    const buttonStyles: StyleObject = {
        padding: "0.575rem 1.1rem",
        fontSize: "1em",
        lineHeight: "1.5",
        borderRadius: "0.25rem",
        color: "#fff",
        backgroundColor: "#007bff",
        border: "1px solid #007bff",
        display: "inline-block",
        fontWeight: "400",
        textAlign: "center",
        verticalAlign: "middle",
        userSelect: "none",
        backgroundClip: "padding-box",
        transition:
            "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        margin: "15px 0px"
    };
    applyStyles(button, buttonStyles);
}

function setNgReasonTextStyles(paragraph: HTMLParagraphElement) {
    const paragraphStyles: StyleObject = {
        paddingLeft: "1em"
    };
    applyStyles(paragraph, paragraphStyles);
}

/**
 * ボタンを右寄せにするために適応するラッパー
 * @param wrapper ラッパー
 */
function setWrapperStyles(wrapper: HTMLDivElement) {
    const wrapperStyles: StyleObject = {
        // textAlign: "right"
    };
    applyStyles(wrapper, wrapperStyles);
}

/**
 *
 * @param resultData
 * @param container
 */
function renderExaminationResult(
    resultData: ExaminationResponse,
    container: HTMLElement
) {
    const examinationResult = document.createElement("p");
    const exResult = resultData.examination_result;
    examinationResult.textContent = `審査結果: ${exResult}`;

    container.innerHTML = "";
    container.appendChild(examinationResult);

    // NGの場合にはコンテナのスタイルを変更
    setContainerStyles(container, exResult === "NG" || exResult === "REVIEW");

    if ((exResult === "NG" || exResult === "REVIEW") && resultData.ng_points) {
        const ngReasonText = document.createElement("p");

        ngReasonText.textContent = `審査${exResult}となった理由は以下です`;
        setNgReasonTextStyles(ngReasonText);
        container.appendChild(ngReasonText);

        const ngPointsList = document.createElement("ul");

        resultData.ng_points.forEach(point => {
            const listItem = document.createElement("li");
            listItem.textContent = `${point.description} (${point.code})`;
            ngPointsList.appendChild(listItem);
        });
        container.appendChild(ngPointsList);
    }
}

function renderErrorMessages(errorMessages: string[], container: HTMLElement) {
    // コンテナの内容をクリア
    container.innerHTML = "";

    // エラーメッセージが空の場合は何も表示しない
    if (!errorMessages.length) {
        return;
    }

    // エラーメッセージのタイトルを追加
    const errorTitle = document.createElement("p");
    errorTitle.textContent =
        "審査必須項目が入力されていないため、審査できませんでした：";
    container.appendChild(errorTitle);

    // エラーメッセージのリストを作成
    const errorList = document.createElement("ul");
    errorMessages.forEach(message => {
        const listItem = document.createElement("li");
        const fieldName = message.replace("no ", "");
        // fieldNameがEcieldConstants.standardEcieldIdsに含まれていることをチェック
        if (isInStandardEcieldIds(fieldName)) {
            // 型アサーションを使用してfieldNameを適切な型にキャスト
            const safeFieldName = fieldName;

            // 対応表から日本語名を取得
            const translatedName =
                standardEcieldIdFieldTranslations[safeFieldName];
            listItem.textContent = translatedName;
        } else {
            listItem.textContent = fieldName;
        }
        errorList.appendChild(listItem);
    });
    container.appendChild(errorList);
}

/**
 * コンテナがすでに表示済みかどうかを判定し、ない場合は作成、ある場合は初期化
 * @param parentElement 結果表示を行うコンテナの親要素
 * @returns 結果表示用のコンテナの実体
 */
function ensureResultContainer(parentElement: HTMLElement): HTMLElement {
    const resultElementId = "examination-result-container";
    let resultContainer = document.getElementById(resultElementId);

    if (!resultContainer) {
        resultContainer = document.createElement("div");
        resultContainer.id = resultElementId;
        setContainerStyles(resultContainer, false);
        parentElement.appendChild(resultContainer);
    } else {
        while (resultContainer.firstChild) {
            resultContainer.removeChild(resultContainer.firstChild);
        }
    }

    return resultContainer;
}

/**
 * 審査ボタンが押下された時の動作
 * @param targetElement 審査結果を描画する要素
 */
function handleButtonClick(targetElement: HTMLElement) {
    const resultContainer = ensureResultContainer(targetElement);
    verifyByEcield<ExaminationResponse>()
        .then(result => {
            console.log("結果", result);
            if (result.success) {
                renderExaminationResult(result.data, resultContainer);
            } else {
                renderErrorMessages(result.errors, resultContainer);
            }
        })
        .catch(error => {
            console.error("審査に失敗しました", error);
        });
}

/**
 *
 * @param parentId
 * @returns
 */
function insertButtonAndDisplayResult(parentId: string): void {
    const parentElement = document.getElementById(parentId);
    if (!parentElement) {
        console.error(
            "ECieldで審査するためのボタンを設置できませんでした",
            "設置の対象としたidがHTML上に存在しないようです",
            parentId
        );
        return;
    }

    const button = document.createElement("button");
    button.textContent = "ECieldで審査";
    setButtonStyles(button);

    const wrapperDiv = document.createElement("div");
    wrapperDiv.appendChild(button);
    setWrapperStyles(wrapperDiv);

    parentElement.appendChild(wrapperDiv);

    button.addEventListener("click", () => handleButtonClick(parentElement));
}

export {
    initializeButtons,
    openModal,
    closeModal,
    insertButtonAndDisplayResult
};
