import axios from "axios";
import { EcieldConstants } from "../constants/index";

const cartItemEmptyForEgg = (cartDomain: string) => {
    // 削除処理後、数秒待機させるためのロジック
    const sleep = (waitMsec: number) => {
        const startMsec = new Date();
        while (new Date().getTime() - startMsec.getTime() < waitMsec);
    };

    /* カートの中身を削除する */
    const deleteAllCartItems = () => {
        void axios
            .get(
                // "https://develop.temonalab.com/shop/display_cart"
                EcieldConstants.cartDeleteItemForEggUrl(cartDomain)
            )
            .then(res => {
                // ユーザーのHTMLをDOMパーサーで解析
                const parser = new DOMParser();
                const doc = parser.parseFromString(
                    res.data as string,
                    "text/html"
                );

                // カートを削除するaタグを丸ごと取得
                const allDeleteCartItemLinks = doc.querySelectorAll(
                    "a[href^='/shop/delete_item']"
                );

                // 取得したaタグのurlの部分のみ抜き出し配列作成
                const deleteCartItemUrls: string[] = Array.from(
                    allDeleteCartItemLinks
                ).map(
                    (link: Element) =>
                        (link as HTMLAnchorElement).getAttribute("href")!
                );

                // aタグに割り振られた連番のidがパラメータに必要なので数の大きいものから順に実行するため
                deleteCartItemUrls.reverse();

                // 取得した削除urlを全部叩く
                for (const cartItemDeleteUrl of deleteCartItemUrls) {
                    void axios.get(cartItemDeleteUrl);
                    sleep(1000);
                    // 数秒待機させないとカートが消せない
                }
            });
    };

    deleteAllCartItems();
};

const cartItemEmptyForStore = (cartDomain: string) => {
    /* カートの中身を削除する */
    const deleteAllCartItems = () => {
        void axios.patch(
            EcieldConstants.cartDeleteItemForSubscUrl(cartDomain),
            {
                cartItems: {
                    last_time_update_cart: ""
                    // ないと発火しない仕様になっている
                }
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document
                        .querySelector("meta[name='csrf-token']")
                        .getAttribute("content")
                }
            }
        );
    };

    deleteAllCartItems();
};

const cartItemEmpty = (cartDomain: string, cartSystem: string) => {
    if (cartSystem === "egg-cart") {
        cartItemEmptyForEgg(cartDomain);
    }
    if (cartSystem.includes("subsc")) {
        cartItemEmptyForStore(cartDomain);
    }
};

export { cartItemEmpty };
