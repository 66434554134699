import { EcieldConstants } from "../constants";
import { EcieldConfig, ShopInfo } from "../types";
import { isContentless } from "./";

/* eslint-disable @typescript-eslint/no-use-before-define */
function concatenateTamagoFieldValues(
    id: string[],
    systemScreenType: string,
    join_character: string = ""
): string {
    const elementValues = [id[0], id[1], id[2]].map(id =>
        getElementTextContent(id, systemScreenType)
    );

    if (elementValues.every(element => element)) {
        return elementValues.join(join_character);
    } else {
        return "";
    }
}

/**
 * 指定されたキーに対応する文字列値をオブジェクトから取得する関数です。
 * キーに対応する値が文字列でない場合、指定されたフォールバック値を返します。
 *
 * @param data - 検索するオブジェクト
 * @param key - 取得したい値のキー
 * @param fallback - 文字列値が見つからない場合のフォールバック値（デフォルトはundefined）
 * @return キーに対応する文字列値またはフォールバック値
 */
function getStringValueOrFallback(
    data: any,
    key: string,
    fallback: string | undefined = undefined
): string {
    return typeof data[key] === "string" ? data[key] : fallback;
}

function getElementTextContent(id: string, systemScreenType: string = "") {
    let element: HTMLElement | null;

    switch (systemScreenType) {
        case EcieldConstants.eggCartManagementFlag: {
            switch (id) {
                case EcieldConstants.eggCartManagementShippingTel: {
                    element = document.getElementsByName(id)[0];
                    if (element) {
                        break;
                    } else {
                        return concatenateTamagoFieldValues(
                            EcieldConstants.tamagoUserTelId,
                            systemScreenType
                        );
                    }
                }
                case EcieldConstants.eggCartManagementShippingSubTel: {
                    element = document.getElementsByName(id)[0];
                    if (element) {
                        break;
                    } else {
                        return concatenateTamagoFieldValues(
                            EcieldConstants.tamagoUserSubTelId,
                            systemScreenType
                        );
                    }
                }
                case EcieldConstants.eggCartManagementDeliveryTel: {
                    return concatenateTamagoFieldValues(
                        EcieldConstants.tamagoDeliveryTelId,
                        systemScreenType
                    );
                }
                case EcieldConstants.eggCartManagementDeliverySubTel: {
                    return concatenateTamagoFieldValues(
                        EcieldConstants.tamagoDeliverySubTelId,
                        systemScreenType
                    );
                }
                case EcieldConstants.eggCartManagementUserBirthday: {
                    return concatenateTamagoFieldValues(
                        EcieldConstants.tamagoUserBirthdayId,
                        systemScreenType,
                        "-"
                    );
                }
                default: {
                    element = document.getElementsByName(id)[0];
                    break;
                }
            }
            break;
        }
        case EcieldConstants.eggCartLpFlag: {
            switch (id) {
                case EcieldConstants.eggCartLpShippingTel: {
                    element = document.getElementsByName(id)[0];
                    if (element) {
                        break;
                    } else {
                        return concatenateTamagoFieldValues(
                            EcieldConstants.tamagoLpUserTelId,
                            systemScreenType
                        );
                    }
                }
                case EcieldConstants.eggCartLpShippingSubTel: {
                    element = document.getElementsByName(id)[0];
                    if (element) {
                        break;
                    } else {
                        return concatenateTamagoFieldValues(
                            EcieldConstants.tamagoLpUserSubTelId,
                            systemScreenType
                        );
                    }
                }
                case EcieldConstants.eggCartLpUserBirthday: {
                    return concatenateTamagoFieldValues(
                        EcieldConstants.tamagoLpUserBirthdayId,
                        systemScreenType,
                        "-"
                    );
                }
                case EcieldConstants.eggCartLpUserEmail: {
                    element = document.getElementsByName(id)[1];
                    break;
                }
                default: {
                    element = document.getElementsByName(id)[0];
                    break;
                }
            }
            break;
        }
        case EcieldConstants.eggCartFrontFlag: {
            switch (id) {
                case EcieldConstants.eggCartFrontUserBirthday: {
                    return concatenateTamagoFieldValues(
                        EcieldConstants.tamagoFrontendUserBirthdayId,
                        systemScreenType,
                        "-"
                    );
                }
                case EcieldConstants.eggCartFrontUserEmailName: {
                    element = document.getElementById(
                        EcieldConstants.eggCartFrontUserEmailId
                    );
                    break;
                }
                default: {
                    element = document.getElementsByName(id)[0];
                    break;
                }
            }
            break;
        }

        default: {
            element = document.getElementById(id);
            break;
        }
    }

    let value: string | null | undefined;

    if (element) {
        if (
            element instanceof HTMLInputElement ||
            element instanceof HTMLSelectElement
        ) {
            value = element.value || null;
        } else {
            value = element.textContent || null;
        }
    }

    return value?.replace(/^'|'$/g, "");
}

/**
 * 与えられたオブジェクトのキーを、指定された値のリストに基づいてフィルタリングします。
 * オブジェクトの各キーについて、その値が値のリストに含まれている場合にのみキーを返します。
 * @param customizedIds キーと値のペアを持つオブジェクト
 * @param values フィルタリングに使用する値のリスト
 * @returns フィルタリングされたキーのリスト
 */
function getKeysByValues(
    customizedIds: { [key: string]: string },
    values: string[]
): string[] {
    const valuesSet = new Set(values);
    return Object.keys(customizedIds).filter(key =>
        valuesSet.has(customizedIds[key])
    );
}

/**
 * 指定されたカスタマイズIDまたはデフォルトIDを使用して、システムスクリーンタイプに基づいてテキスト内容を取得します。
 * 最初にカスタマイズIDで内容を取得しようとし、それが失敗した場合にはデフォルトIDで試みます。
 * @param customizedId カスタマイズされた識別子
 * @param defaultId デフォルトの識別子
 * @param systemScreenType システムスクリーンのタイプ
 * @returns 取得したテキスト内容または未定義
 */
function getDataWithFallback(
    customizedId: string,
    defaultId: string,
    systemScreenType: string
): string | undefined {
    const byCustomizedIdValue = getElementTextContent(
        customizedId,
        systemScreenType
    );

    return (
        byCustomizedIdValue ||
        getElementTextContent(defaultId, systemScreenType)
    );
}

/**
 * 送信ボタンのdisabledを操作する
 * @param ecieldConfig 設定（isSubmitButtonDisabledUntilExamination=trueの時に）
 * @param isDisabled 表示状態
 * @returns none
 */
function setElementDisabledStateById(
    ecieldConfig: EcieldConfig,
    isDisabled: boolean
): void {
    if (
        !(
            ecieldConfig.isSubmitButtonDisabledUntilExamination &&
            ecieldConfig.useScreen === EcieldConstants.managementFlag
        )
    ) {
        return;
    }

    ecieldConfig.submitButtonIds.forEach(buttonId => {
        const element = document.getElementById(buttonId);
        if (element) {
            element.toggleAttribute("disabled", isDisabled);
        }
    });
}

// cookieNameに対応するCookieを検索
function findCookie(cookieName: string): string | null {
    return (
        document.cookie
            .split("; ")
            .map(cookie => cookie.split("="))
            .find(([name]) => name === cookieName)?.[1] || null
    );
}

function checkForIdsOrNamesInDocument(
    attributes: Record<string, string>
): boolean {
    let count = 0;

    Object.values(attributes).forEach(value => {
        if (
            document.getElementById(value) ||
            document.getElementsByName(value).length > 0
        ) {
            count++;
        }
    });

    console.log("id数:", count, count >= 4);

    return count >= 4;
}

/**
 * たまご、魂の楽天payに遷移するボタンを取得する
 * @returns 楽天のボタン（ない場合はundefined）
 */
export const getEggRakutenButton = () => {
    return document.querySelector(
        "input[type='image'][src^='https://checkout.rakuten.co.jp']"
    );
};

/**
 * 指定されたクラス名（querySelectorではじめにヒットしたもの）の表示状態を返す
 * @param className
 * @returns
 */
export const isElementDisplayed = (element: Element) => {
    const style = window.getComputedStyle(element);
    return (
        style.display !== "none" &&
        style.visibility !== "hidden" &&
        style.opacity !== "0"
    );
};

/**
 * Fraud Buster入力フィールドの値を取得します。
 * 入力フィールドが見つからない場合は`null`を返します。
 */
function getFraudBusterInputValue(): string | null {
    const fraudbusterInput = document.getElementById(
        "fraudbuster"
    ) as HTMLInputElement | null;
    return fraudbusterInput ? fraudbusterInput.value : null;
}

/**
 * 指定されたIdに対応する送信ボタンを検索
 * @param id - 検索対象のId
 * @param ecieldConfig - Ecield設定情報
 * @returns 送信ボタン、またはnull。
 */
const findSubmitButtons = (
    id: string,
    ecieldConfig: EcieldConfig
): HTMLElement | null => {
    // ストア画面で楽天ボタンがあるかどうか
    const rakutenConfirmButtonClass = ".rakuten-confirm-button-image";
    const isExistRakutenConfirmButton =
        document.querySelector(rakutenConfirmButtonClass) != null;

    // 魂画面で楽天ボタンがあるかどうか
    const eggRakutenButtonElement = getEggRakutenButton();

    // ストア、たまご&魂で楽天ボタンがある(支払い方法が楽天Payの)場合
    if (isExistRakutenConfirmButton || eggRakutenButtonElement) {
        switch (ecieldConfig.systemScreenType) {
            case EcieldConstants.eggCartFrontFlag:
            case EcieldConstants.eggCartSoulLpConfirmFlag:
            case "egg-cart-soul": {
                return eggRakutenButtonElement as HTMLElement;
            }
            default: {
                return document.querySelector(rakutenConfirmButtonClass);
            }
        }
    } else {
        switch (ecieldConfig.systemScreenType) {
            case EcieldConstants.eggCartFrontFlag: {
                // return document.querySelector(`input[id='${id}']`);
                return document.querySelector(
                    `input[id*='${id}'], input[name*='${id}']`
                );
            }
            case "egg-cart-soul": {
                const inputButtons = document.querySelectorAll(
                    "input[type='submit']"
                );

                if (inputButtons.length > 0) {
                    return inputButtons[0] as HTMLElement;
                }

                switch (id) {
                    case EcieldConstants.eggCartSoulLpConfirmDirectSubmitButtonName: {
                        return document.querySelector(
                            `input[type='submit'][name='${id}']`
                        );
                    }
                    default: {
                        return document.getElementById(id);
                    }
                }
            }
            default: {
                // ストアでかつpaypay利用かどうか
                const paypayButtonContentString = "PayPay決済に進む";
                const buttonsOnDom = Array.from(
                    document.querySelectorAll("button")
                );
                const target = buttonsOnDom.find(button => {
                    return button.textContent === paypayButtonContentString;
                });
                if (target) {
                    return target;
                } else {
                    return document.getElementById(id);
                }
            }
        }
    }
};

/**
 * 指定された要素の上に重なるブロッカー要素作成
 * @param targetElement - ブロッカー要素が重なる対象のHTMLElement
 * @returns - 作成されたブロッカー要素のHTMLDivElement
 */
const createBlockerElement = (targetElement: HTMLElement) => {
    const blockerElm = document.createElement("div");

    blockerElm.id = `e-button-wrapper-${targetElement.id}`;
    blockerElm.style.position = "absolute";
    blockerElm.style.width = `${targetElement.offsetWidth}px`;
    blockerElm.style.height = `${targetElement.offsetHeight}px`;
    blockerElm.style.top = `${targetElement.offsetTop}px`;
    blockerElm.style.left = `${targetElement.offsetLeft}px`;
    blockerElm.style.zIndex = "1000";
    blockerElm.style.backgroundColor = "transparent";
    blockerElm.style.cursor = "pointer";

    const hoverStyle = {
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)"
    };

    blockerElm.addEventListener("mouseover", () => {
        blockerElm.style.boxShadow = hoverStyle.boxShadow;
    });

    blockerElm.addEventListener("mouseout", () => {
        blockerElm.style.boxShadow = "";
    });

    return blockerElm;
};

/**
 * ショップ情報を取得
 * @param shopInfo - 取得したショップ情報をマージするための既存の ShopInfo オブジェクト。
 * @returns - ショップ情報
 */
const fetchShopInfo = (shopInfo: ShopInfo): ShopInfo => {
    const data = {
        shop_id: getElementTextContent("ecield_shop_id"),
        cart_system: getElementTextContent("ecield_cart_system"),
        cart_system_id: getElementTextContent("ecield_cart_system_id")
    };
    data.shop_id = isContentless(data.shop_id)
        ? shopInfo.shop_id
        : data.shop_id;
    data.cart_system = isContentless(data.cart_system)
        ? shopInfo.cart_system
        : data.cart_system;
    data.cart_system_id = isContentless(data.cart_system_id)
        ? shopInfo.cart_system_id
        : data.cart_system_id;
    return data;
};

/**
 * ショップ情報が有効かどうかを検証
 * @param data - 検証するショップ情報
 * @returns ショップ情報が有効な場合はtrue、それ以外の場合はfalseを返します
 */
function validateShopInfo(data: ShopInfo): boolean {
    if (
        isContentless(data.shop_id) &&
        (isContentless(data.cart_system) || isContentless(data.cart_system_id))
    ) {
        return false;
    }
    return true;
}

export {
    getElementTextContent,
    getStringValueOrFallback,
    getFraudBusterInputValue,
    getDataWithFallback,
    findCookie,
    getKeysByValues,
    setElementDisabledStateById,
    checkForIdsOrNamesInDocument,
    findSubmitButtons,
    createBlockerElement,
    fetchShopInfo,
    validateShopInfo
};
