import { ecieldDefaultIds } from "../ecield_constants/ecield-default-constants";
export const subscDefaultConstants = {
    SubscLPDefaultIds: {
        ecield_event_customers_deliveries_specified_deliver_datetime:
            "jsUkPreferredDeliveryOn",
        ecield_event_customers_deliveries_delivery_specified_existence:
            "jsUkPreferredDeliveryOn",
        ecield_event_customers_deliveries_name_first_name:
            "jsUkShippingAddressFirstName",
        ecield_event_customers_deliveries_name_last_name:
            "jsUkShippingAddressFamilyName",
        ecield_event_customers_deliveries_address_zipcode:
            "jsUkShippingAddressZipCode",
        ecield_event_customers_deliveries_address_address_a:
            "shop_event_customers_deliveries_address_address_a",
        ecield_event_customers_deliveries_address_address_b:
            "jsUkShippingAddressCity",
        ecield_event_customers_deliveries_address_address_c:
            "jsUkShippingAddressStreetAddress",
        ecield_event_customers_deliveries_address_address_d:
            "jsUkShippingAddressBuildingName",
        ecield_event_customers_deliveries_tel_fixed_number:
            "jsUkShippingAddressTel",
        ecield_event_customers_deliveries_tel_mobile_number:
            "jsUkShippingAddressSubTel",
        ecield_event_customers_deliveries_email_pc_account:
            "shop_event_customers_deliveries_email_pc_account",
        ecield_event_customers_deliveries_email_mobile_account:
            "shop_event_customers_deliveries_email_mobile_account",
        ecield_event_customers_deliveries_company_name:
            "jsUkShippingAddressCorporateName",
        ecield_shop_id: "default_ecield_shop_id",
        ecield_cart_system: "default_ecield_cart_system",
        ecield_cart_system_id: "default_ecield_cart_system_id",
        ecield_event_ec_settle_limit_price: "shop_event_ec_settle_limit_price",
        ecield_event_ec_settle_status: "shop_event_ec_settle_status",
        ecield_event_ec_settle_datetime: "shop_event_ec_settle_datetime",
        ecield_event_ec_settle_amount: "shop_event_ec_settle_amount",
        ecield_event_ec_settle_method: "shop_event_ec_settle_method",
        ecield_event_customers_buyer_name_first_name: "jsUkProfileFirstName",
        ecield_event_customers_buyer_name_last_name: "jsUkProfileFamilyName",
        ecield_event_customers_buyer_name_first_name_kana:
            "jsUkProfileFirstNameKana",
        ecield_event_customers_buyer_name_last_name_kana:
            "jsUkProfileFamilyNameKana",
        ecield_event_customers_buyer_address_zipcode: "jsUkProfileZipCode",
        ecield_event_customers_buyer_address_address_a:
            "shop_event_customers_buyer_address_address_a",
        ecield_event_customers_buyer_address_address_b: "jsUkProfileCity",
        ecield_event_customers_buyer_address_address_c:
            "jsUkProfileStreetAddress",
        ecield_event_customers_buyer_address_address_d:
            "jsUkProfileBuildingName",
        ecield_event_customers_buyer_tel_fixed_number: "jsUkProfileTel",
        ecield_event_customers_buyer_tel_mobile_number: "jsUkProfileSubTel",
        ecield_event_customers_buyer_pc_account: "jsUkEmail",
        ecield_event_customers_buyer_mobile_account: "jsUkSubEmail",
        ecield_event_customers_buyer_name_company_name:
            "jsUkProfileCorporateName"
    },

    subscManagementRequiredIds: [
        ecieldDefaultIds.ids.ecield_event_customers_buyer_name_first_name,
        ecieldDefaultIds.ids.ecield_event_customers_buyer_name_last_name,
        ecieldDefaultIds.ids.ecield_event_customers_buyer_address_address_b,
        ecieldDefaultIds.ids.ecield_event_customers_buyer_address_address_c,
        ecieldDefaultIds.ids.ecield_event_customers_buyer_tel_fixed_number
    ]
};
