import { isAxiosError } from "axios";
import {
    isIpBlockDataObject,
    isTimeElapsedInMinutes,
    localStorageHandlers
} from "../utils";
import { redirectModal } from "../components";

/**
 * ローカルストレージの値を確認し、ipブロック対象の場合、リダイレクトさせる
 *
 * 処理として、Promiseを発行することで、後続の処理を待機させることで止めています
 * @returns Promiseを返すものの、待機のためなので、特に意味はない
 */
function ipBlockRedirect() {
    const data = localStorageHandlers.ipBlock.getValue();

    if (data) {
        const { modal, blockTime: blockTimeISOString } = data;
        const blockTime = new Date(blockTimeISOString);

        if (!isTimeElapsedInMinutes(blockTime.getTime(), 10)) {
            redirectModal(modal.message, modal.redirectUrl, modal.title, true);

            return new Promise<void>(resolve => {
                // promiseを返し、リダイレクトが成功するまで後続を長期間待機
                // 何もできないようにする
                setTimeout(() => {
                    console.log("リダイレクト処理完了");
                    resolve();
                }, 1000000);
            });
        } else {
            localStorageHandlers.ipBlock.remove();
        }
    }
}

/**
 * ipブロックに関する情報をローカルストレージに保存する
 * dataが不正な形の場合はデフォルトを設定する
 * @param data ipブロックに関するデータ
 */
function setIpBlockData(data: unknown) {
    if (isAxiosError(data) && isIpBlockDataObject(data.response.data)) {
        localStorageHandlers.ipBlock.setValue(data.response.data);
    } else if (isIpBlockDataObject(data)) {
        localStorageHandlers.ipBlock.setValue(data);
    } else {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const now = new Date();

        localStorageHandlers.ipBlock.setValue({
            // ここの値は見ないので、なんでもいい
            message: "ローカルストレージが不正に書き換えられています",
            modal: {
                title: "在庫切れのお知らせ",
                message: "お求めいただきました商品は現在品切れ中です",
                redirectUrl: `${protocol}//${host}`
            },
            blockTime: now.toISOString(),
            unblockTime: ""
        });
    }
}

/**
 * ipブロックに関する情報をローカルストレージに保存する
 *
 * コールされた際、dataの値に従ってモーダルを作成
 * dataが不正な形の場合はデフォルトを設定してリダイレクトさせる
 *
 * コール = ipブロック動作開始が確定するので、コールタイミングは注意するように
 * @param data ipブロックに関するデータ
 */
async function setIpBlockDataAndRedirect(data: unknown) {
    setIpBlockData(data);
    await ipBlockRedirect();
}

export { ipBlockRedirect, setIpBlockDataAndRedirect };
