import { EcieldConstants } from "../constants";
import {
    ItemExaminationType,
    DeliveriesType,
    ExaminationData,
    ShopInfo,
    EcieldConfig
} from "../types";
import { isContentless } from "./data-validation";
import { getIndexesForMatchingKeys } from "./helpers";
import {
    getElementTextContent,
    getStringValueOrFallback,
    getFraudBusterInputValue,
    getDataWithFallback
    // findCookie
} from "./dom-manipulation";

/**
 * `ecield_event_customers_items_`という文字列がkeyにあった場合、削除する
 * @param obj keyを指定のprefixで削除したいオブジェクト
 * @param prefix 削除するプレフィックス
 * @returns 指定されたプレフィックスがkeyから削除された新しいオブジェクト
 */
function removePrefixFromKeys(
    obj: Record<string, string | null>,
    prefix: string
) {
    const newObject: Record<string, string | null> = {};

    Object.keys(obj).forEach(key => {
        const newKey = key.startsWith(prefix) ? key.slice(prefix.length) : key;
        newObject[newKey] = obj[key];
    });

    return newObject;
}

function readItemsData(elementIds: {
    [key: string]: string;
}): ItemExaminationType[] {
    const itemsData: ItemExaminationType[] = [];

    const itemKeys: { [key: string]: string } = {};

    if (elementIds == null) {
        return itemsData;
    }

    for (const key of EcieldConstants.itemEcieldKeys) {
        if (elementIds.hasOwnProperty(key) && !isContentless(elementIds[key])) {
            itemKeys[key] = elementIds[key];
        }
    }

    let groupSet = getIndexesForMatchingKeys(Object.values(itemKeys));

    if (groupSet.size === 0) {
        for (const key of EcieldConstants.itemEcieldKeys) {
            if (
                EcieldConstants.defaultIds.hasOwnProperty(key) &&
                !isContentless(EcieldConstants.defaultIds[key] as string)
            ) {
                itemKeys[key] = EcieldConstants.defaultIds[key] as string;
            }
        }

        groupSet = getIndexesForMatchingKeys(Object.values(itemKeys));
    }

    for (const value of groupSet.values()) {
        const itemData: Record<string, string | null> = {};

        for (const key in itemKeys) {
            if (itemKeys.hasOwnProperty(key)) {
                const elementId = `${itemKeys[key]}[${value}]`;
                const element = document.getElementById(elementId);
                if (element) {
                    itemData[key] = element.textContent || "";
                } else {
                    itemData[key] = null;
                }
            }
        }

        itemsData.push(itemData);
    }

    /** 不要な文字列をkeyから削除したオブジェクト */
    const prefixItemsData = itemsData.map(itemData =>
        removePrefixFromKeys(itemData, "ecield_event_customers_items_")
    );

    return prefixItemsData;
}

function readCustomerDeliveriesData(
    elementInfo: { [key: string]: string },
    systemScreenType: string
): DeliveriesType[] {
    const customerData: DeliveriesType[] = [];
    const deliverieKeys: { [key: string]: string } = {};
    const defaultDeliverieKeys: { [key: string]: string } = {};

    if (elementInfo == null) {
        return customerData;
    }

    const defaultIds: { [key: string]: string } = EcieldConstants.defaultIds;

    for (const key of EcieldConstants.deliveryEcieldKeys) {
        if (defaultIds.hasOwnProperty(key) && !isContentless(defaultIds[key])) {
            defaultDeliverieKeys[key] = defaultIds[key];
        }
        if (
            elementInfo.hasOwnProperty(key) &&
            !isContentless(elementInfo[key])
        ) {
            deliverieKeys[key] = elementInfo[key];
        }
    }

    let groupSet: Set<number>;

    switch (systemScreenType) {
        case EcieldConstants.eggCartManagementFlag:
        case EcieldConstants.subscLpOneClickFlag: {
            groupSet = new Set([1]);
            break;
        }
        default: {
            groupSet = getIndexesForMatchingKeys(Object.values(deliverieKeys));

            if (groupSet.size === 0) {
                groupSet = getIndexesForMatchingKeys(
                    Object.values(defaultDeliverieKeys)
                );
            }
            break;
        }
    }

    for (const groupId of groupSet.values()) {
        const deleveryData: Record<string, string | null> = {};

        for (const key in deliverieKeys) {
            if (deliverieKeys.hasOwnProperty(key)) {
                let value: string | null;
                let elementId: string;

                switch (systemScreenType) {
                    case EcieldConstants.eggCartManagementFlag:
                    case EcieldConstants.subscLpOneClickFlag: {
                        elementId = deliverieKeys[key];
                        break;
                    }
                    default: {
                        elementId = `${deliverieKeys[key]}[${groupId}]`;
                        break;
                    }
                }

                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                value = getElementTextContent(elementId, systemScreenType);

                // カスタマイズされた値が存在しない場合は、デフォルト値を指定します
                if (!value) {
                    elementId = `${defaultDeliverieKeys[key]}[${groupId}]`;
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    value = getElementTextContent(elementId, systemScreenType);
                }

                deleveryData[key] = value;
            }
        }

        const structuredEventData: DeliveriesType = {};

        structuredEventData.id =
            deleveryData.ecield_event_customers_deliveries_id;
        structuredEventData.birth_day =
            deleveryData.ecield_event_customers_deliveries_birth_day;
        structuredEventData.sex =
            deleveryData.ecield_event_customers_deliveries_sex;
        structuredEventData.specified_deliver_datetime =
            deleveryData.ecield_event_customers_deliveries_specified_deliver_datetime;
        structuredEventData.delivery_specified_existence =
            deleveryData.ecield_event_customers_deliveries_delivery_specified_existence;
        structuredEventData.delivery_frequency =
            deleveryData.ecield_event_customers_deliveries_delivery_frequency;
        structuredEventData.delivery_company_name =
            deleveryData.ecield_event_customers_deliveries_delivery_company_nameny_name;
        structuredEventData.name = {
            first_name:
                deleveryData.ecield_event_customers_deliveries_name_first_name,
            last_name:
                deleveryData.ecield_event_customers_deliveries_name_last_name
        };
        structuredEventData.address = {
            country: "JP",
            country_name: "日本",
            zipcode:
                deleveryData.ecield_event_customers_deliveries_address_zipcode,
            address_a:
                deleveryData.ecield_event_customers_deliveries_address_address_a,
            address_b:
                deleveryData.ecield_event_customers_deliveries_address_address_b,
            address_c:
                deleveryData.ecield_event_customers_deliveries_address_address_c,
            address_d:
                deleveryData.ecield_event_customers_deliveries_address_address_d
        };
        structuredEventData.tel = {
            fixed_number:
                deleveryData.ecield_event_customers_deliveries_tel_fixed_number,
            mobile_number:
                deleveryData.ecield_event_customers_deliveries_tel_mobile_number
        };
        structuredEventData.email = {
            pc: {
                account:
                    deleveryData.ecield_event_customers_deliveries_email_pc_account,
                domain: deleveryData.ecield_event_customers_deliveries_email_pc_domain
            },
            mobile: {
                account:
                    deleveryData.ecield_event_customers_deliveries_email_mobile_account,
                domain: deleveryData.ecield_event_customers_deliveries_email_mobile_domain
            }
        };
        structuredEventData.company = {
            name: deleveryData.ecield_event_customers_deliveries_company_name,
            department:
                deleveryData.ecield_event_customers_deliveries_company_department,
            post: deleveryData.ecield_event_customers_deliveries_company_post
        };

        customerData.push(structuredEventData);
    }

    return customerData;
}

function extractStructuredExaminationData(
    elementInfo: { [key: string]: string },
    shopInfo: ShopInfo,
    ecieldConfig: EcieldConfig
): ExaminationData {
    if (elementInfo == null) {
        return {
            info: {}
        };
    }

    let customizedIds: { [key: string]: string } = {};
    let defaultIds: { [key: string]: string } = {};
    const extractedData: { [key: string]: string } = {};
    const structuredEventData: ExaminationData = { info: {} };
    let defaultCustomerBuyerId: string = "";

    for (const key of EcieldConstants.standardEcieldKeys) {
        customizedIds[key] =
            elementInfo[key] || EcieldConstants.defaultIds[key];
        defaultIds[key] = EcieldConstants.defaultIds[key];
    }

    customizedIds = { ...customizedIds, ...EcieldConstants.shopMainKeys };
    defaultIds = { ...defaultIds, ...EcieldConstants.shopMainKeys };

    for (const key in customizedIds) {
        extractedData[key] = getDataWithFallback(
            customizedIds[key],
            defaultIds[key],
            ecieldConfig.systemScreenType
        );
    }

    structuredEventData.info = {
        shop_id: getStringValueOrFallback(
            extractedData,
            "ecield_shop_id",
            shopInfo.shop_id
        ),
        cart_system: getStringValueOrFallback(
            extractedData,
            "ecield_cart_system",
            shopInfo.cart_system
        ),
        cart_system_id: getStringValueOrFallback(
            extractedData,
            "ecield_cart_system_id",
            shopInfo.cart_system_id
        ),
        domain: shopInfo.domain
    };

    if (structuredEventData.info.cart_system.includes("egg")) {
        defaultCustomerBuyerId = getElementTextContent(
            EcieldConstants.tamagoCustomerBuyerId,
            ecieldConfig.systemScreenType
        );
    }

    structuredEventData.event = {
        device_info: getFraudBusterInputValue(),
        // o-pluxで送信不要となったためコメントアウト
        // TODO: クッキー削除が正式に決まったら消す
        // cookie_only: findCookie("fraudbuster-key"),
        ec: {
            settle: {
                limit_price: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_ec_settle_limit_price"
                ),
                status: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_ec_settle_status"
                ),
                datetime: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_ec_settle_datetime"
                ),
                amount: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_ec_settle_amount"
                ),
                method: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_ec_settle_method"
                ),
                credit_card: {
                    number_hash: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_ec_settle_credit_card_number_hash"
                    ),
                    bincode: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_ec_settle_credit_card_bincode"
                    ),
                    authori_info: {
                        authori_code: getStringValueOrFallback(
                            extractedData,
                            "ecield_event_ec_settle_credit_card_authori_info_authori_code"
                        )
                    },
                    issuer_foreign_flg: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_ec_settle_credit_card_issuer_foreign_flg"
                    )
                }
            }
        },
        customers: {
            buyer: {
                id: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_customers_buyer_ID",
                    defaultCustomerBuyerId
                ),
                birth_day: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_customers_buyer_birth_day"
                ),
                sex: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_customers_buyer_sex"
                ),
                name: {
                    first_name: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_first_name"
                    ),
                    last_name: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_last_name"
                    )
                },
                name_kana: {
                    first_name_kana: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_first_name_kana"
                    ),
                    last_name_kana: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_last_name_kana"
                    )
                },
                address: {
                    country: "JP",
                    countryName: "日本",
                    zipcode: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_address_zipcode"
                    ),
                    addressA: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_address_address_a"
                    ),
                    addressB: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_address_address_b"
                    ),
                    addressC: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_address_address_c"
                    ),
                    addressD: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_address_address_d"
                    )
                },
                tel: {
                    fixed_number: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_tel_fixed_number"
                    ),
                    mobile_number: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_tel_mobile_number"
                    )
                },
                email: {
                    pc: {
                        account: getStringValueOrFallback(
                            extractedData,
                            "ecield_event_customers_buyer_pc_account"
                        ),
                        domain: getStringValueOrFallback(
                            extractedData,
                            "ecield_event_customers_buyer_pc_domain"
                        )
                    },
                    mobile: {
                        account: getStringValueOrFallback(
                            extractedData,
                            "ecield_event_customers_buyer_mobile_account"
                        ),
                        domain: getStringValueOrFallback(
                            extractedData,
                            "ecield_event_customers_buyer_mobile_domain"
                        )
                    }
                },
                company: {
                    name: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_company_name"
                    ),
                    department: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_company_department"
                    ),
                    post: getStringValueOrFallback(
                        extractedData,
                        "ecield_event_customers_buyer_name_company_post"
                    )
                },
                existing_customer_flg: getStringValueOrFallback(
                    extractedData,
                    "ecield_event_customers_buyer_name_existing_customer_flg"
                )
            },
            deliveries: readCustomerDeliveriesData(
                elementInfo,
                ecieldConfig.systemScreenType
            )
        },
        items: readItemsData(elementInfo),
        tenant: {
            shop_tenant_id: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_shop_tenant_id"
            ),
            tenant_limit_price: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_tenant_limit_price"
            ),
            member_limit_change_ratio: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_member_limit_change_ratio"
            ),
            tenant_name: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_tenant_name"
            ),
            tenant_url: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_tenant_url"
            ),
            tenant_registration_date: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_tenant_registration_date"
            ),
            site_id: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_site_id"
            ),
            site_pass: getStringValueOrFallback(
                extractedData,
                "ecield_event_customers_tenant_site_pass"
            )
        }
    };

    return structuredEventData;
}

export { extractStructuredExaminationData, readItemsData };
