import {
    EcieldConstants,
    eggCartManagementRequiredIds,
    subscManagementRequiredIds
} from "./constants";

import {
    closeModal,
    insertButtonAndDisplayResult,
    redirectModal
} from "./components";

import {
    TenantInfo,
    EcieldResult,
    ShopInfo,
    StringArray,
    EcieldConfig,
    OriginalPushOrReplaceState,
    WarningModalInfo,
    InitEcieldValues,
    ExaminationData
} from "./types";
import {
    extractStructuredExaminationData,
    validateData,
    loadFraudBusterScript,
    transformAxiosErrorToMessage,
    createErrorResponse,
    createSuccessResponse,
    getKeysByValues,
    setElementDisabledStateById,
    combineEcieldConfigurations,
    mergeInitializationValues,
    convertToHashObject,
    isExaminationDto,
    isAxiosErrorWithResponseStatus,
    checkForIdsOrNamesInDocument,
    isElementDisplayed,
    getDefaultPaths,
    getEggRakutenButton,
    localStorageHandlers,
    ChangeStatusData,
    cartItemEmpty,
    sendGetRequest,
    sendPostRequest,
    delay,
    isSkipFlagActive,
    findSubmitButtons,
    createBlockerElement,
    getLatestExaminationData,
    getLatestExaminationResult,
    fetchShopInfo,
    validateShopInfo,
    sortObject,
    isTimeElapsedInMinutes
} from "./utils";
import {
    ipBlockRedirect,
    postOrderUidToEcieldApi,
    setIpBlockDataAndRedirect,
    statusChange
} from "./actions";
import { IdTemplateSdkResponse } from "types/class/dto/info-id.dto";
import {
    Customers,
    ExaminationResponse
} from "types/class/dto/examination.dto";
import {
    defaultConfigShopInfo,
    defaultConfigEcieldConfig
} from "./configs/ecield.config";
import { VerifyUserResponse } from "types/class/dto/verify-user.dto";
import { getTestQueryParams, saveTestQueryParams } from "./utils/test-order";
import {
    sendCompleteLog,
    sendCreateLog,
    startLogTransaction
} from "./utils/logger";

declare global {
    interface Window {
        ecieldApi: {
            verifyByEcield: () => Promise<EcieldResult<unknown>>;
            initEcield: (initValues: {
                shopInfo: ShopInfo;
                tenantInfo: TenantInfo;
                requiredIds: StringArray;
                ecieldConfig: EcieldConfig;
            }) => void;
        };
        React: object;
        /** 魂のformを次に進める関数（常に表示の場合、これで購入確定処理を呼び出す */
        form_submit_js: () => void;
        /** たまご組み込みの、購入ボタンクリック後の読み込み表示関数 */
        show_loading: (n: number) => void;
    }
}

// 製品版でコンソールを出力しないように
if (process.env.NODE_ENV === "production") {
    console.log = function () {};
}
/** ショップ情報 */
let shopInfo: ShopInfo = defaultConfigShopInfo;
/** Ecieldの設定 */
let ecieldConfig: EcieldConfig = defaultConfigEcieldConfig;

let elementIds: { [key: string]: string } | null = null;
let elementNames: { [key: string]: string } | null = null;
let requiredIds: StringArray = [];
let isLoadedElementIds: boolean = false;
/** 元の送信ボタンが保存されているかどうか*/
let isSavedOriginalSubmitButton: boolean = false;
const originSubmitButtons: Record<string, HTMLElement | null> = {};
/** 審査済みかどうか */
let isDataValidated = false;
/** 最終データを保存 */
let latestExaminationData: Customers | null = null;
let latestExaminationResult: ExaminationResponse | null = null;
let isSdkDisabled: boolean = false;
/** たまごでclickが仕込まれていたので、それを吸い取った変数 */
let clickAction: () => void;
/** 完了ボタンに埋め込まれているonClickを吸い取った変数 */
// eslint-disable-next-line @typescript-eslint/ban-types
let onClickAction: Function;
/** clickActionが実行されたかどうか */
let isUseClickAction: boolean = false;
/** 魂のformのsubmitを持った要素 */
let submitButtonElement: EventTarget;
/** ボタンラッパーを利用したかどうか */
let isUseBlocker = false;

function toggleSdkAvailability(value: boolean = true) {
    isSdkDisabled = value;
}

/**
 * 注文のステータス変更時の処理を行います
 * @param examinationResult - 審査結果
 * @param submitButtonId - 送信ボタンのID
 */
const handleStatusChange = (
    examinationResult: ExaminationResponse,
    submitButtonId: string | null
) => {
    // CHANGE_STATUS処理
    // REVIEWの場合は「REDIRECT」になっていてもこちらの処理を通す
    const changeStatusData: ChangeStatusData = {
        isStatusChangeRequired: true, // 出荷停止処理をする
        isModalDisplayRequired: true, // モーダルはまだ表示されていない
        isUseLpOneclick:
            ecieldConfig.useScreen === "lp-oneclick" ? true : false,
        title: examinationResult.title,
        message: examinationResult.message,
        customerId: examinationResult.customer_id,
        eventId: examinationResult.event_id
    };

    localStorageHandlers.changeStatusData.setValue({
        timestamp: Date.now(),
        data: changeStatusData
    });

    statusChange();

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    changeSubmitButtonActions("rollback_and_submit", {}, submitButtonId);
};

/**
 * 審査値がNG場合、リダイレクト時の処理を行います
 * @param examinationResult - 審査結果
 * @param isSubmitRequired - 送信が必要かどうかを示すブール値
 */
const handleRedirect = (
    examinationResult: ExaminationResponse,
    isSubmitRequired: boolean
) => {
    // REDIRECT処理
    if (
        examinationResult.is_cart_item_empty &&
        !(ecieldConfig.useScreen === "lp-oneclick")
    ) {
        /* カートを空にする処理。*/
        cartItemEmpty(examinationResult.cart_domain, shopInfo.cart_system);
    }

    if (Object.keys(originSubmitButtons).length !== 0 && !isSubmitRequired) {
        /** 結果がNGの場合、送信ボタンのonclick機能が変更され、警告モーダルが開きます。 */
        /** 前の動作が送信ボタンによるもので、isSubmitRequired が true の場合は、すぐにモーダルが表示。 */

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        changeSubmitButtonActions("remove_action", {
            title: examinationResult.title,
            message: examinationResult.message,
            action: examinationResult.action
        });
    } else {
        // 確認画面に進むボタンをHTMLから消す
        for (const buttonId in originSubmitButtons) {
            if (originSubmitButtons.hasOwnProperty(buttonId)) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const element = findSubmitButtons(buttonId, ecieldConfig);
                element?.remove();
            }
        }
        console.log("create redirectModal");
        redirectModal(
            examinationResult.message,
            examinationResult.action.toString(),
            examinationResult.title,
            true
        );
    }
};

/**
 * 審査結果がOKの場合の処理を行います
 * @param examinationResult - 審査結果
 * @param submitButtonId - 送信ボタンのId
 */
const handleOkResult = (
    examinationResult: ExaminationResponse,
    submitButtonId: string | null
) => {
    // NGまたは、REVIEWかつis_hold_on_review（REVIEWを出荷停止にするフラグ）がON以外の処理（例：OK）
    isDataValidated = false;
    const changeStatusData: ChangeStatusData = {
        isStatusChangeRequired: false, // 出荷停止処理をしない
        isModalDisplayRequired: false, // 審査OKならモーダル不要
        isUseLpOneclick:
            ecieldConfig.useScreen === "lp-oneclick" ? true : false,
        customerId: examinationResult.customer_id,
        eventId: examinationResult.event_id
    };
    localStorageHandlers.changeStatusData.setValue({
        timestamp: Date.now(),
        data: changeStatusData
    });

    statusChange();

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    changeSubmitButtonActions("rollback_and_submit", {}, submitButtonId);
};

/**
 * Apiのレスポンスに対するアクションを処理
 * @param examinationResult - 審査結果
 * @param isSubmitRequired - 送信が必要かどうかを示すブール値
 * @param submitButtonId - 送信ボタンのId
 */

function handleApiResponseActions(
    examinationResult: ExaminationResponse,
    isSubmitRequired: boolean,
    submitButtonId: string | null
) {
    if (
        examinationResult.examination_result === "NG" ||
        (examinationResult.examination_result === "REVIEW" &&
            examinationResult.is_hold_on_review)
    ) {
        // NGまたは、REVIEWかつis_hold_on_review（REVIEWを出荷停止にするフラグ）がON
        isDataValidated = false;

        if (
            examinationResult.use_rule === "CHANGE_STATUS" ||
            (examinationResult.use_rule === "REDIRECT" &&
                examinationResult.examination_result === "REVIEW")
        ) {
            handleStatusChange(examinationResult, submitButtonId);
        } else if (examinationResult.use_rule === "REDIRECT") {
            handleRedirect(examinationResult, isSubmitRequired);
        } else {
            console.log("ステータス変更でもリダイレクトでもない");
        }
    } else {
        handleOkResult(examinationResult, submitButtonId);
    }
}
/**
 * スキップフラグのアクション処理
 * @param isSubmitRequired - 送信が必要かどうかを示すブール値
 * @param submitButtonId - 送信ボタンのId
 */
const handleSkipFlagActions = (
    isSubmitRequired: boolean,
    submitButtonId: string | null
): void => {
    const response: ExaminationResponse = {
        examination_result: "OK",
        cart_domain: ""
    };

    if (ecieldConfig.useScreen === EcieldConstants.managementFlag) {
        return;
    }

    handleApiResponseActions(response, isSubmitRequired, submitButtonId);
};

/**
 * たまごの読み込み中を呼び出す
 */
function callEggLoadScript() {
    if (
        shopInfo.cart_system === "egg-cart" &&
        ecieldConfig.useScreen !== "management"
    ) {
        for (let i = 0; i < 6; i++) {
            window.show_loading(i);
        }
    }
}

/**
 * 注文情報審査実行
 * @param data 注文の情報
 * @param isSubmitRequired 送信が必要かどうかを示すブール値
 * @param submitButtonId 送信ボタンのId
 */
export async function verifyData<T>(
    data: ExaminationData,
    isSubmitRequired: boolean = false,
    submitButtonId: string | null = null
): Promise<EcieldResult<T>> {
    /** disableになったときのSDKの動作を除去する */
    if (isSdkDisabled) {
        return;
    }

    await sendCreateLog(
        {
            label: "verifyData",
            obj: {
                dataInfo: data.info,
                isSubmitRequired: isSubmitRequired,
                submitButtonId: submitButtonId
            }
        },
        shopInfo.shop_id
    );

    callEggLoadScript();

    // ipブロック状態かどうかを確認し、対象の場合は後続を実施しない
    await ipBlockRedirect();

    if (isSkipFlagActive()) {
        console.log("skip examination");
        handleSkipFlagActions(isSubmitRequired, submitButtonId);
        return;
    }

    if (!isExaminationDto(data)) {
        return createErrorResponse(["Fail"]);
    }

    let isNewRequestNeeded: boolean = true;

    /** isSubmitRequired はSubmitアクションの作成を目的としています。 */
    const errors = validateData(data, requiredIds);
    if (errors.length > 0) {
        console.log("data validation errors: ", errors);
        return createErrorResponse(errors);
    }

    let retryCount = 0;
    const maxRetries = 3;
    const retryDelay = 7000;

    console.log("exData:", data);

    closeModal();

    while (retryCount < maxRetries && !isDataValidated) {
        if (isExaminationDto(data)) {
            const newExaminationCustomerData = convertToHashObject(
                data.event.customers
            );

            /** ソート後の前回審査結果 */
            const sortedLatest = sortObject(latestExaminationData);
            /** ソート後の今回審査結果 */
            const sortedNew = sortObject(newExaminationCustomerData);

            // 前回と審査用のデータが同じだった場合は審査を行わない
            if (latestExaminationData && !retryCount) {
                console.log(
                    `前回審査${
                        JSON.stringify(sortedLatest) ===
                        JSON.stringify(sortedNew)
                    }`,
                    submitButtonId
                );
                if (
                    JSON.stringify(sortedLatest) === JSON.stringify(sortedNew)
                ) {
                    if (isSubmitRequired) {
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        changeSubmitButtonActions(
                            "rollback_and_submit",
                            {},
                            submitButtonId
                        );
                    }

                    latestExaminationData = newExaminationCustomerData;
                    console.log("equal (no examination)");

                    if (latestExaminationResult) {
                        isNewRequestNeeded = false;
                        console.log(
                            "最新のexaminationResultを使用してアクションを続行する"
                        );
                    } else {
                        return;
                    }
                }
            }
            latestExaminationData = newExaminationCustomerData;
        }

        try {
            // ipアドレスに対してアクセス制限がかかっている場合、コールしない
            // if (isIpBlocked) break;

            await sendCreateLog(
                {
                    label: "verifyDataTry",
                    obj: {
                        retryCount: retryCount,
                        ecieldConfig: ecieldConfig,
                        isNewRequestNeeded: isNewRequestNeeded
                    }
                },
                shopInfo.shop_id
            );

            const isManagementScreen = ecieldConfig.useScreen == "management";
            let examinationResult = latestExaminationResult;

            const formattedQueryParams = getTestQueryParams();
            const ecieldVerifyDataUrlWithParams = `${EcieldConstants.ecieldVerifyDataUrl}${formattedQueryParams}`;

            if (isNewRequestNeeded) {
                const responsePromise = sendPostRequest<ExaminationResponse>(
                    ecieldVerifyDataUrlWithParams,
                    { ...data, is_management: isManagementScreen }
                );
                const response = await responsePromise;

                examinationResult = response.data;
                examinationResult = examinationResult;

                await sendCompleteLog(shopInfo.shop_id);

                latestExaminationResult = examinationResult;
            }

            // 管理画面の場合はそのまま結果をreturn
            if (ecieldConfig.useScreen === EcieldConstants.managementFlag) {
                return createSuccessResponse(examinationResult as unknown as T);
            }

            retryCount++;

            handleApiResponseActions(
                examinationResult,
                isSubmitRequired,
                submitButtonId
            );

            return createSuccessResponse(examinationResult as unknown as T);
        } catch (error) {
            const errorMessage = transformAxiosErrorToMessage(error);

            retryCount++;

            // 一旦、403はipブロック扱い
            if (
                isAxiosErrorWithResponseStatus(error) &&
                error.response.status === 403
            ) {
                console.log(error);
                if (latestExaminationResult) {
                    if (
                        ecieldConfig.useScreen ===
                        EcieldConstants.managementFlag
                    ) {
                        return createSuccessResponse(
                            latestExaminationResult as unknown as T
                        );
                    }

                    handleApiResponseActions(
                        latestExaminationResult,
                        isSubmitRequired,
                        submitButtonId
                    );

                    return createSuccessResponse(
                        latestExaminationResult as unknown as T
                    );
                } else {
                    // ExaminationRequestの作成が失敗した時と同様の処理を行う
                    console.error(transformAxiosErrorToMessage(error));

                    clickAction = null;
                    onClickAction = null;

                    await setIpBlockDataAndRedirect(error);

                    setElementDisabledStateById(ecieldConfig, false);
                    toggleSdkAvailability();
                }
                return;
            }

            if (retryCount < maxRetries) {
                await delay(retryDelay);
            } else {
                // disableを解除する
                setElementDisabledStateById(ecieldConfig, false);
                // SDKを無効にする
                toggleSdkAvailability();

                // ボタンの機能を復元する
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                changeSubmitButtonActions(
                    "rollback_and_submit",
                    {},
                    isSubmitRequired ? submitButtonId : null
                );
                return createErrorResponse<T>([errorMessage]);
            }
        }
    }

    // disableを解除する
    setElementDisabledStateById(ecieldConfig, false);

    return createErrorResponse(["Max retries reached"]);
}

/**
 * 注文データを検証し、結果を返信
 * @param isSubmitRequired - 送信が必要かどうかを示すブール値
 * @param submitButtonId - 送信ボタンのId
 * @returns - 検証結果
 */
export async function verifyByEcield<T>(
    isSubmitRequired: boolean = false,
    submitButtonId: string | null = null
): Promise<EcieldResult<T>> {
    /** disableになったときのSDKの動作を除去する */
    if (isSdkDisabled) {
        return;
    }

    await sendCreateLog(
        {
            label: "verifyByEcield",
            obj: {
                isSubmitRequired: isSubmitRequired,
                submitButtonId: submitButtonId
            }
        },
        shopInfo.shop_id
    );

    if (!elementIds && !elementNames) {
        return createErrorResponse(["cant load element ids"]);
    }

    let elementInfo: { [key: string]: string };

    switch (ecieldConfig.systemScreenType) {
        case EcieldConstants.eggCartManagementFlag: {
            elementInfo = elementNames;
            break;
        }
        default: {
            elementInfo = elementIds;
            break;
        }
    }

    const data = extractStructuredExaminationData(
        elementInfo,
        shopInfo,
        ecieldConfig
    );
    return verifyData(data, isSubmitRequired, submitButtonId);
}

async function handleBlockerClick(targetElement: HTMLElement) {
    try {
        const result = await verifyByEcield<ExaminationResponse>(
            false,
            targetElement.id
        );
        const examinationResult = result.data;

        // 本来のボタンをクリックさせると
        if (
            examinationResult &&
            examinationResult.examination_result === "NG" &&
            examinationResult.use_rule === "REDIRECT"
        ) {
            redirectModal(
                examinationResult.message,
                examinationResult.action.toString(),
                examinationResult.title,
                true
            );
            // たまごの要素が変わっていたので
            // targetElement.remove();
        } else {
            if (!isUseClickAction) {
                onClickAction();

                if (!(shopInfo.cart_system === "egg-cart-soul")) {
                    clickAction();
                }

                isUseClickAction = false;
            }
        }
    } catch (error) {
        console.error("エラーが発生しました: ", error);
        onClickAction();
        clickAction();
        isUseClickAction = false;
    }
}

const blocker = (targetElement: HTMLElement) => {
    const blockerElm = createBlockerElement(targetElement);

    // ブロッカーにクリックイベントリスナーを追加
    blockerElm.addEventListener("click", () => {
        void handleBlockerClick(targetElement);
    });

    // ブロッカーを元の要素の親要素に追加
    targetElement.parentNode.insertBefore(blockerElm, targetElement);
};

/**
 * アクションを除去し、送信ボタンを押すしたら、リダイレクトモーダルを表示
 * @param buttonId - ボタンのId
 * @param targetButton - 送信ボタン
 * @param {WarningModalInfo} warningModalInfo - 警告モーダル情報
 */
const changeActionRemoveAction = (
    buttonId: string,
    targetButton: HTMLElement | null,
    warningModalInfo: WarningModalInfo
): void => {
    const redirectModalHandler = () => {
        redirectModal(
            warningModalInfo.message,
            warningModalInfo.action.toString(),
            warningModalInfo.title,
            true
        );
    };
    // 魂はform-submitで管理しているので、それを乗っ取る
    if (shopInfo.cart_system === "egg-cart-soul") {
        redirectModalHandler();
    } else {
        const cloneButton: HTMLElement = originSubmitButtons[
            buttonId
        ].cloneNode(true) as HTMLElement;
        if (cloneButton instanceof HTMLAnchorElement) {
            cloneButton.removeAttribute("href");
        }
        cloneButton.dataset.isReplaceByEcield = "true";
        if (cloneButton instanceof HTMLButtonElement) {
            cloneButton.setAttribute("type", "button");
        }

        targetButton.parentNode?.replaceChild(cloneButton, targetButton);

        targetButton.click = () => {};
        cloneButton.click = () => {};

        cloneButton.onclick = () => {
            redirectModalHandler();
        };
    }
};

/**
 * アクションに検証を追加します。
 * @param buttonId - ボタンのId
 * @param targetButton - 送信ボタン
 */
const changeActionAddActionVerify = (
    buttonId: string,
    targetButton: HTMLElement | null
): void => {
    const rakutenButton = getEggRakutenButton();
    const normalButtons = document.querySelectorAll("input[type='submit']");

    // 魂はform-submitで管理しているので、それを乗っ取る
    if (
        shopInfo.cart_system === "egg-cart-soul" &&
        (rakutenButton || normalButtons.length !== 0)
    ) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        const handleClick = (event: Event) => {
            event.preventDefault();
            const targetSubmitButton = event.target as HTMLInputElement;
            submitButtonElement = targetSubmitButton;
            void verifyByEcield();
        };

        if (rakutenButton) {
            rakutenButton.addEventListener("click", function (event) {
                handleClick(event);
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            normalButtons.forEach(button => {
                button.addEventListener("click", function (event) {
                    handleClick(event);
                });
            });
        }
    } else {
        if (typeof clickAction !== "function") {
            clickAction = targetButton.click.bind(targetButton) as () => void;
        }

        onClickAction = targetButton.onclick;

        if (
            shopInfo.cart_system === "egg-cart" ||
            shopInfo.cart_system === "egg-cart-soul"
        ) {
            blocker(targetButton);
            isUseBlocker = true;
        }

        const cloneButton: HTMLElement = originSubmitButtons[
            buttonId
        ].cloneNode(true) as HTMLElement;

        if (cloneButton instanceof HTMLAnchorElement) {
            cloneButton.removeAttribute("href");
        }

        cloneButton.dataset.isReplaceByEcield = "true";
        if (cloneButton instanceof HTMLButtonElement) {
            cloneButton.setAttribute("type", "button");
        }

        targetButton.parentNode?.replaceChild(cloneButton, targetButton);

        // TODO: ループは発生し得ないので一旦無視
        // ただ、警告が出るような実装が間違っているので要修正
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        cloneButton.onclick = () => {
            isDataValidated = false;
            void verifyByEcield(true, buttonId);
        };
    }
};

/**
 * 魂のフォームを送信し、ロールバックアクションを実行
 */
const changeActionRollbackAndSubmitEggCartSoul = (): void => {
    try {
        const form = (submitButtonElement as HTMLInputElement).form;

        if (form) {
            form.submit();
        }
    } catch {
        // 魂の購入画面内に用意されているフォーム送信関数
        window.form_submit_js();
    }
};

/**
 * ロールバックアクションを実行し、フォームを送信
 * @param buttonId - ボタンのId
 * @param targetButton - 送信ボタン
 * @param submitButtonId - 送信ボタンのId
 */
const changeActionRollbackAndSubmit = (
    buttonId: string,
    targetButton: HTMLElement | null,
    submitButtonId: string | null = null
): void => {
    if (isUseBlocker && shopInfo.cart_system === "egg-cart-soul") return;

    const originalButton = originSubmitButtons[buttonId];
    targetButton.parentNode?.replaceChild(originalButton, targetButton);

    if (submitButtonId && buttonId === submitButtonId) {
        isDataValidated = false;
        originalButton.click();
    }

    if (
        shopInfo.cart_system === "egg-cart-soul" &&
        getEggRakutenButton() &&
        !isUseClickAction
    ) {
        clickAction();
        isUseClickAction = false;
    }
};

/**
 * rollback_and_submit_executed を監視してリセットする関数
 */
function setupMutationObserver() {
    const observer = new MutationObserver(() => {
        const resetInterval = 3000;

        setTimeout(() => {
            const rollbackAndSubmitData =
                localStorageHandlers.isRollbackAndSubmitExecuted.getValue();
            if (rollbackAndSubmitData) {
                localStorageHandlers.isRollbackAndSubmitExecuted.remove();
            }
        }, resetInterval);
    });

    observer.observe(document.body, { childList: true, subtree: true });
}

/**
 * 送信ボタンのアクションを変更します。
 * @param type - アクションのタイプ
 * @param warningModalInfo - 警告モーダルの情報
 * @param submitButtonId - 送信ボタンのIs
 * @returns {void} - 何も返しません。
 */
function changeSubmitButtonActions(
    type: "remove_action" | "add_action_verify" | "rollback_and_submit",
    warningModalInfo: WarningModalInfo = {},
    submitButtonId: string | null = null
) {
    for (const buttonId in originSubmitButtons) {
        if (originSubmitButtons.hasOwnProperty(buttonId)) {
            const targetButton: HTMLElement | null = findSubmitButtons(
                buttonId,
                ecieldConfig
            );
            if (!targetButton) {
                continue;
            }

            switch (type) {
                case "remove_action": {
                    console.log("ecield remove_action");
                    changeActionRemoveAction(
                        buttonId,
                        targetButton,
                        warningModalInfo
                    );
                    break;
                }
                case "add_action_verify": {
                    console.log("ecield add_action_verify");
                    changeActionAddActionVerify(buttonId, targetButton);
                    break;
                }
                case "rollback_and_submit": {
                    // rollback_and_submit時にだけMutationObserverを設定する
                    setupMutationObserver();

                    const isRollbackData =
                        localStorageHandlers.isRollbackAndSubmitExecuted.getValue();

                    /** ローカルストレージにisRollbackAndSubmitExecutedが存在し、保存されてから10秒以内かどうか */
                    const isRollbackAndSubmitExecuted =
                        isRollbackData?.data && isRollbackData?.timestamp
                            ? !isTimeElapsedInMinutes(
                                  isRollbackData.timestamp,
                                  0.1
                              )
                            : false;

                    /** localStorageから取得したuseScreen */
                    const useScreen =
                        localStorageHandlers.useScreenType.getValue()?.data ||
                        null;

                    /** 画面上にpaidy/楽天のボタンが存在するかどうか */
                    const isSpecificPaymentMethod =
                        document.getElementById("subsc-submit-order-bottom")
                            ?.innerText === "Paidy決済に進む" ||
                        document.querySelector(
                            ".rakuten-confirm-button-image"
                        ) !== null;

                    let specificPaymentMethodCount = 0;

                    // LPワンクリック×paidy/楽天の場合、購入確認画面で一度だけ購入ボタンを押下できるように
                    if (
                        isRollbackAndSubmitExecuted &&
                        useScreen === "lp-oneclick" &&
                        isSpecificPaymentMethod &&
                        specificPaymentMethodCount < 1
                    ) {
                        specificPaymentMethodCount++;

                        // 魂はform-submitなので、formを強制的に呼び出す
                        // blockerを利用している場合はform-submitを利用しない
                        if (
                            shopInfo.cart_system === "egg-cart-soul" &&
                            !isUseBlocker
                        ) {
                            // 魂の申し込むボタンはinput要素
                            changeActionRollbackAndSubmitEggCartSoul();
                            return;
                        } else {
                            changeActionRollbackAndSubmit(
                                buttonId,
                                targetButton,
                                submitButtonId
                            );
                            return;
                        }
                    } else if (isRollbackAndSubmitExecuted) {
                        console.log(
                            "rollback_and_submitは既に実行されています"
                        );
                        return;
                    }

                    localStorageHandlers.isRollbackAndSubmitExecuted.setValue({
                        timestamp: Date.now(),
                        data: true
                    });

                    localStorageHandlers.useScreenType.setValue({
                        timestamp: Date.now(),
                        data: ecieldConfig.useScreen
                    });

                    console.log("ecield rollback_and_submit");
                    // 魂はform-submitなので、formを強制的に呼び出す
                    // blockerを利用している場合はform-submitを利用しない
                    if (
                        shopInfo.cart_system === "egg-cart-soul" &&
                        !isUseBlocker
                    ) {
                        // 魂の申し込むボタンはinput要素
                        changeActionRollbackAndSubmitEggCartSoul();
                    } else {
                        changeActionRollbackAndSubmit(
                            buttonId,
                            targetButton,
                            submitButtonId
                        );
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }
}

/**
 * Eggカート管理画面向けの設定を指定
 */
const setupForEggCartManagement = (): void => {
    elementNames = EcieldConstants.defaultTamagoNames;
    elementIds = {};
    requiredIds = getKeysByValues(elementNames, eggCartManagementRequiredIds);
    setElementDisabledStateById(ecieldConfig, true);
};

/**
 * EggCartLp管理画面向けの設定を指定
 */
const setupForEggCartLp = (): void => {
    elementNames = EcieldConstants.defaultTamagoLpNames;
    elementIds = {};
    requiredIds = getKeysByValues(elementNames, requiredIds);
};

/**
 * EggCartFront管理画面向けの設定を指定
 */
const setupForEggCartFront = (): void => {
    elementNames = EcieldConstants.defaultTamagoFrontendNames;
    elementIds = {};
    requiredIds = getKeysByValues(elementNames, requiredIds);
};

/**
 * 他のカート管理画面向けの設定を指定
 */
const setupForOtherCases = async (): Promise<void> => {
    await sendCreateLog(
        {
            label: "setupForOtherCases",
            obj: { ecieldConfig: ecieldConfig, shopInfo: shopInfo }
        },
        shopInfo.shop_id
    );

    const useScreen =
        ecieldConfig.useScreen === "lp-confirm" ? "lp" : ecieldConfig.useScreen;

    try {
        if (shopInfo.cart_system === "egg-cart") {
            switch (ecieldConfig.useScreen) {
                case "lp":
                case "front":
                    elementIds = EcieldConstants.defaultIds;
                    break;
            }
        } else if (shopInfo.cart_system === "egg-cart-soul") {
            switch (ecieldConfig.useScreen) {
                case "lp":
                case "front":
                case "lp-confirm":
                    elementIds = EcieldConstants.defaultIds;
                    break;
            }
        } else if (shopInfo.cart_system.includes("subsc")) {
            switch (ecieldConfig.useScreen) {
                case "lp-oneclick":
                    elementIds = EcieldConstants.SubscLPDefaultIds;
                    break;
            }
        }

        // idがない場合、サーバーから取得する
        if (!elementIds) {
            const response = await sendGetRequest<IdTemplateSdkResponse>(
                EcieldConstants.ecieldShopCustomizeIdUrl,
                {
                    user_id: shopInfo.shop_id,
                    use_screen: useScreen,
                    domain: shopInfo.domain
                }
            );
            elementIds = response.data.values;
        }

        // elementIds = EcieldConstants.sampleGetCustomizeIdsResponse;
        console.log("id等", elementIds);

        if (elementIds) {
            if (ecieldConfig.useScreen === EcieldConstants.managementFlag) {
                requiredIds = getKeysByValues(
                    elementIds,
                    subscManagementRequiredIds
                );
            } else {
                requiredIds = getKeysByValues(elementIds, requiredIds);
            }
        }
    } catch (error) {
        console.log("error", error);
        console.error(transformAxiosErrorToMessage(error));
    }
};

/**
 * 初期化、ショップのカスタマイズIdを取得
 */
async function getShopCustomizeId(): Promise<void> {
    await sendCreateLog(
        {
            label: "getShopCustomizeId",
            obj: { isLoadedElementIds: isLoadedElementIds, shopInfo: shopInfo }
        },
        shopInfo.shop_id
    );

    if (isLoadedElementIds) {
        return;
    }

    const data = fetchShopInfo(shopInfo);

    if (!validateShopInfo(data)) {
        return;
    }

    ecieldConfig.systemScreenType = `${data.cart_system || ""}-${
        ecieldConfig.useScreen || ""
    }`;
    isLoadedElementIds = true;

    switch (ecieldConfig.systemScreenType) {
        case EcieldConstants.eggCartManagementFlag: {
            void setupForEggCartManagement();
            return;
        }
        case EcieldConstants.eggCartLpFlag: {
            void setupForEggCartLp();
            return;
        }
        case EcieldConstants.eggCartFrontFlag: {
            void setupForEggCartFront();
            return;
        }

        default: {
            await setupForOtherCases();
        }
    }
}

/**
 * amazon pay v1のためだけの処理
 * じゃない支払方法の場合は普通にchangeSubmitButtonActions関数を呼び出す
 */
function monitorButtonAndExecuteAction() {
    // URLからクエリパラメータを取得
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");

    console.log(shopInfo.cart_system.includes("subsc"), type === "amazon");

    if (shopInfo.cart_system.includes("subsc") && type === "amazon") {
        // ボタンの監視
        const buttonElement = document.getElementById(
            "subsc-submit-order-bottom"
        );
        const button = buttonElement as HTMLButtonElement;

        if (button) {
            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (
                        mutation.type === "attributes" &&
                        mutation.attributeName === "disabled"
                    ) {
                        // disabled属性が変更された場合、その状態をチェック
                        if (
                            !button.disabled &&
                            checkForIdsOrNamesInDocument(
                                EcieldConstants.defaultIds
                            )
                        ) {
                            // ボタンが活性化されている場合、アクションを実行
                            changeSubmitButtonActions("add_action_verify");
                            observer.disconnect(); // 監視を終了
                        }
                    }
                });
            });

            // 監視の開始
            observer.observe(button, { attributes: true });
        }
    } else {
        // typeがamazonでない場合、ただちにアクションを実行
        changeSubmitButtonActions("add_action_verify");
    }
}

/**
 * 提供されたIDリストに基づいて、元の送信ボタンをチェックして保存
 * 元の送信ボタンがすでに保存されている場合、何もしません。
 *
 * @param submitButtonIds - 保存する必要がある送信ボタンのIDの配列
 */
function setOriginSubmitButtons(submitButtonIds: string[]) {
    if (isSavedOriginalSubmitButton) {
        return;
    }
    submitButtonIds.forEach(buttonId => {
        const buttonElement = findSubmitButtons(buttonId, ecieldConfig);

        if (buttonElement) {
            isSavedOriginalSubmitButton = true;
            originSubmitButtons[buttonId] = buttonElement;
        }
    });
}

/**
 * 特別な送信ボタンのハンドラーを処理
 * @param systemScreenType - システムスクリーンタイプ
 */
function specialSubmitButtonHandler(systemScreenType) {
    switch (systemScreenType) {
        case EcieldConstants.eggCartSoulLpConfirmFlag: {
            // egg_cart_soulのLPページでは、LPの送信ボタンが押された後に確認フォームの送信ボタンが表示されます
            // そのため、新しく作成されたボタンにアクションを関連付けるためのイベントを作成する必要があります。
            document.addEventListener("click", function (event) {
                if (event.target instanceof HTMLElement) {
                    if (
                        event.target.id ===
                        EcieldConstants.eggCartSoulLpConfirmDisplayBelowSubmitButtonId
                    ) {
                        setTimeout(() => {
                            setOriginSubmitButtons(
                                ecieldConfig.submitButtonIds
                            );
                            changeSubmitButtonActions("add_action_verify");
                        }, 2000);
                    }
                }
            });
        }
    }
}

/**
 * ユーザー認証
 * @param shopId - ショップId
 * @returns 認証が成功した場合はtrueを、失敗した場合はfalseを返します。
 */
const authenticateUser = async (
    shopId: string | undefined
): Promise<boolean> => {
    try {
        const res = await sendPostRequest<VerifyUserResponse>(
            EcieldConstants.ecieldVerifyUserUrl,
            {
                id: shopId,
                domain: window.location.hostname
            }
        );
        if (!res.data.is_authorized) {
            return false;
        }

        shopInfo.shop_id = res.data.id;
        return true;
    } catch (error) {
        console.error("ユーザー認証中にエラーが発生しました:", error);
        return false;
    }
};

/**
 * 管理画面モード指定
 */
const setupManagementMode = () => {
    ecieldConfig.isSubmitButtonDisabledUntilExamination = false;

    let targetId: string;

    if (shopInfo.cart_system.includes("egg")) {
        targetId = EcieldConstants.manualExaminationButtonIds.egg;
    } else {
        // デフォルトは一旦ストアに
        targetId = EcieldConstants.manualExaminationButtonIds.subsc;
    }

    void insertButtonAndDisplayResult(targetId);
};

/**
 * 初期化と設定の更新をする関数
 */
const loadScript = async (values: {
    shopInfo: ShopInfo;
    // tenantInfo: TenantInfo;
    requiredIds: StringArray;
    ecieldConfig: EcieldConfig;
}) => {
    const authenticated = await authenticateUser(values.shopInfo.shop_id);

    if (!authenticated) {
        return;
    }

    await sendCreateLog(
        {
            label: "loadScript",
            obj: { values: values }
        },
        shopInfo.shop_id
    );

    shopInfo = mergeInitializationValues(shopInfo, values.shopInfo);
    ecieldConfig = combineEcieldConfigurations(
        ecieldConfig,
        values.ecieldConfig,
        values.shopInfo.cart_system
    );

    // 前回審査結果
    latestExaminationData = void getLatestExaminationData();
    latestExaminationResult = void getLatestExaminationResult();

    requiredIds = values.requiredIds || [];
    ecieldConfig.systemScreenType = `${shopInfo.cart_system || ""}-${
        ecieldConfig.useScreen || ""
    }`;

    console.log("init", ecieldConfig, shopInfo);

    if (!elementIds) {
        void getShopCustomizeId();
    }

    if (ecieldConfig.useScreen === EcieldConstants.managementFlag) {
        void setupManagementMode();
        return;
    }

    setOriginSubmitButtons(ecieldConfig.submitButtonIds);
    specialSubmitButtonHandler(ecieldConfig.systemScreenType);

    monitorButtonAndExecuteAction();
    // changeSubmitButtonActions("add_action_verify");
};

/**
 * SPA対策
 * historyオブジェクトに変更があったらチェックを開始
 * @param checkPathAndLoadScript pathname確認関数
 */
function setupHistoryAPIInterception(checkPathAndLoadScript: () => void) {
    const extendHistoryMethod = (method: "pushState" | "replaceState") => {
        const originalMethod = history[method].bind(
            history
        ) as OriginalPushOrReplaceState;
        history[method] = (...args) => {
            originalMethod(...args);
            checkPathAndLoadScript();
        };
    };

    extendHistoryMethod("pushState");
    extendHistoryMethod("replaceState");
}

/**
 * observerを使ってDOMを監視
 * id = "order_confirm" が表示されたら関数を実行
 * @param values
 */
function setupEggCartSoulObserver(values: InitEcieldValues) {
    const observer = new MutationObserver((mutations, obs) => {
        const element = document.getElementById("order_confirm");
        if (element && isElementDisplayed(element)) {
            void loadScript(values);
            obs.disconnect();
        }
    });

    const buttonWrapperObserver = new MutationObserver(() => {
        const buttonWrapperElement = document.querySelector(
            "[id*='e-button-wrapper']"
        );
        if (!buttonWrapperElement) {
            try {
                changeSubmitButtonActions("add_action_verify");
            } catch (e) {
                console.error(e);
            }
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true
    });

    buttonWrapperObserver.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true
    });

    window.addEventListener("DOMContentLoaded", () => {
        const element = document.getElementById("order_confirm");
        if (element && isElementDisplayed(element)) {
            void loadScript(values);
            observer.disconnect();
        }
    });
}

/**
 * pathnameの判定を行う関数を作成する
 * @param values
 * @returns 判定関数
 */
function createPathChecker(values: InitEcieldValues) {
    return () => {
        const cartSystem = values.shopInfo.cart_system;
        const usePath = values.usePath || getDefaultPaths(cartSystem);

        // ボタン出現条件が違うので、DOMを監視しながら
        if (cartSystem === "egg-cart-soul") {
            setupEggCartSoulObserver(values);
        }

        // lpワンクリック
        if (
            values.ecieldConfig.systemScreenType ===
            EcieldConstants.subscLpOneClickFlag
        ) {
            void loadScript(values);
        }

        // pathnameがあれば実行
        if (usePath.includes(window.location.pathname)) {
            void loadScript(values);
            return;
        }
    };
}

/**
 * 配送方法変更処理が完了したかどうかを確認
 * 完了していない場合は実施
 */
function changeStatus(cartSystem: string) {
    const changeStatusData = localStorageHandlers.changeStatusData.getValue();
    const isUseLpOneclick = changeStatusData.data.isUseLpOneclick;

    // データ作成が1分未満でかつ処理が実施されてない
    if (isUseLpOneclick) {
        statusChange();
    } else {
        postOrderUidToEcieldApi(cartSystem);
    }
}

/**
 * 初期化
 */
function initEcield(values: InitEcieldValues) {
    startLogTransaction();
    changeStatus(values.shopInfo.cart_system);

    saveTestQueryParams(values);

    values.ecieldConfig = combineEcieldConfigurations(
        values.ecieldConfig,
        values.ecieldConfig,
        values.shopInfo.cart_system
    );

    if (values.ecieldConfig.useScreen === "management") {
        void loadScript(values);
        return;
    }

    // pathname checker
    const checkPathAndLoadScript = createPathChecker(values);

    // measures against SPA
    setupHistoryAPIInterception(checkPathAndLoadScript);
    window.addEventListener("popstate", checkPathAndLoadScript);
    window.addEventListener("load", checkPathAndLoadScript);

    // init
    checkPathAndLoadScript();
}

void loadFraudBusterScript();
void getShopCustomizeId();

window.ecieldApi = {
    verifyByEcield,
    initEcield
};
