import { sha3_224 } from "js-sha3";

const hashValue = (value: unknown): string => {
    if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
    ) {
        return sha3_224(value.toString());
    } else if (value === null || value === undefined) {
        return sha3_224("");
    } else {
        throw new Error("Unsupported value type");
    }
};

export const hashObject = (obj: unknown): object | string => {
    if (typeof obj === "object" && obj !== null) {
        if (Array.isArray(obj)) {
            return obj.map(item => hashObject(item));
        } else {
            const newObj: { [key: string]: unknown } = {};
            for (const [key, value] of Object.entries(obj)) {
                newObj[key] = hashObject(value);
            }
            return newObj;
        }
    } else {
        return hashValue(obj);
    }
};
