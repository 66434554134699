import axios, { AxiosResponse } from "axios";

/**
 * 引数の url に対して params を付与した状態で GET 通信を行う
 * @param url リクエスト先
 * @param params GET時に付与するクエリパラメータ
 * @returns
 */
export async function sendGetRequest<T>(
    url: string,
    params: Record<string, unknown> = {}
): Promise<AxiosResponse<T>> {
    try {
        console.log("get req", url, params);
        const response = await axios.get<T>(url, {
            params,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "X-Frontend-Url": window.location.href
            },
            withCredentials: true
        });
        console.log(url, response.data);
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * 引数の url に対して body に postData を付与した状態で POST 通信を行う
 * @param url リクエスト先
 * @param postData request.bodyに付与するクエリパラメータ
 */
export async function sendPostRequest<T>(
    url: string,
    postData: object
): Promise<AxiosResponse<T>> {
    try {
        console.log("post req", url, postData);
        const response = await axios.post<T>(url, postData, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "X-Frontend-Url": window.location.href
            },
            withCredentials: true
        });
        console.log("post res", url, response.data);
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * 引数の url に対して body に postData を付与した状態で DELETE 通信を行う
 * @param url リクエスト先
 * @param postData request.bodyに付与するリクエストボディ
 */
export async function sendDeleteRequest<T>(
    url: string,
    postData: object
): Promise<AxiosResponse<T>> {
    try {
        console.log("delete req", url, postData);
        const response = await axios.delete<T>(url, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "X-Frontend-Url": window.location.href
            },
            data: postData,
            withCredentials: true
        });
        console.log("delete res", url, response.data);
        return response;
    } catch (error) {
        throw error;
    }
}
