import { specialConstant } from "../special-constants";

export const tamagoConstants = {
    defaultTamagoNames: {
        ecield_event_customers_deliveries_specified_deliver_datetime:
            "order[expected_arrival_date]",
        ecield_event_customers_deliveries_delivery_specified_existence:
            "order[expected_arrival_time_zone]",
        ecield_event_customers_deliveries_delivery_frequency:
            "order[delivery_frequency]",
        ecield_event_customers_deliveries_name_first_name:
            "order[delivery_first_name]",
        ecield_event_customers_deliveries_name_last_name:
            "order[delivery_family_name]",
        ecield_event_customers_deliveries_address_zipcode:
            "order[delivery_zip]",
        ecield_event_customers_deliveries_address_address_a:
            "order[delivery_pref]",
        ecield_event_customers_deliveries_address_address_b:
            "order[delivery_city]",
        ecield_event_customers_deliveries_address_address_c:
            "order[delivery_address]",
        ecield_event_customers_deliveries_address_address_d:
            "order[delivery_building]",
        ecield_event_customers_deliveries_tel_fixed_number:
            specialConstant.eggCartManagementDeliverySubTel,
        ecield_event_customers_deliveries_tel_mobile_number:
            "order[delivery_tel]",
        ecield_event_customers_buyer_birth_day:
            specialConstant.eggCartManagementUserBirthday,
        ecield_event_customers_buyer_sex: "user[sex]",
        ecield_event_customers_buyer_name_first_name:
            "shipping_address[first_name]",
        ecield_event_customers_buyer_name_last_name:
            "shipping_address[family_name]",
        ecield_event_customers_buyer_name_first_name_kana:
            "shipping_address[first_name_kana]",
        ecield_event_customers_buyer_name_last_name_kana:
            "shipping_address[family_name_kana]",
        ecield_event_customers_buyer_address_zipcode: "shipping_address[zip]",
        ecield_event_customers_buyer_address_address_a:
            "shipping_address[pref]",
        ecield_event_customers_buyer_address_address_b:
            "shipping_address[city]",
        ecield_event_customers_buyer_address_address_c:
            "shipping_address[address]",
        ecield_event_customers_buyer_address_address_d:
            "shipping_address[building]",
        ecield_event_customers_buyer_tel_fixed_number: "shipping_address[tel]",
        ecield_event_customers_buyer_tel_mobile_number:
            "shipping_address[sub_tel]",
        ecield_event_customers_buyer_pc_account: "user[email]"
    },
    defaultTamagoLpNames: {
        ecield_event_customers_deliveries_specified_deliver_datetime:
            "order[expected_arrival_date]",
        ecield_event_customers_deliveries_delivery_frequency:
            "order[delivery_frequency]",
        ecield_event_customers_buyer_birth_day:
            specialConstant.eggCartLpUserBirthday,
        ecield_event_customers_buyer_sex: "shop_event_customers_buyer_sex",
        ecield_event_customers_buyer_name_last_name:
            "shipping_address[family_name]",
        ecield_event_customers_buyer_name_first_name:
            "shipping_address[first_name]",
        ecield_event_customers_buyer_name_last_name_kana:
            "shipping_address[family_name_kana]",
        ecield_event_customers_buyer_name_first_name_kana:
            "shipping_address[first_name_kana]",
        ecield_event_customers_buyer_address_zipcode: "shipping_address[zip]",
        ecield_event_customers_buyer_address_address_a:
            "shipping_address[pref]",
        ecield_event_customers_buyer_address_address_b:
            "shipping_address[city]",
        ecield_event_customers_buyer_address_address_c:
            "shipping_address[address]",
        ecield_event_customers_buyer_address_address_d:
            "shipping_address[building]",
        ecield_event_customers_buyer_tel_fixed_number:
            specialConstant.eggCartLpShippingTel,
        ecield_event_customers_buyer_tel_mobile_number:
            specialConstant.eggCartLpShippingSubTel,
        ecield_event_customers_buyer_pc_account:
            specialConstant.eggCartLpUserEmailName,
        ecield_event_customers_buyer_mobile_account: "user[sub_email]"
    },
    defaultTamagoFrontendNames: {
        ecield_shop_id: "default_ecield_shop_id",
        ecield_cart_system: "default_ecield_cart_system",
        ecield_cart_system_id: "default_ecield_cart_system_id",
        ecield_event_customers_buyer_name_first_name:
            "shipping_address[first_name]",
        ecield_event_customers_buyer_name_last_name:
            "shipping_address[family_name]",
        ecield_event_customers_buyer_name_first_name_kana:
            "shipping_address[first_name_kana]",
        ecield_event_customers_buyer_name_last_name_kana:
            "shipping_address[family_name_kana]",
        ecield_event_customers_buyer_address_zipcode: "shipping_address[zip]",
        ecield_event_customers_buyer_address_address_a:
            "shipping_address[pref]",
        ecield_event_customers_buyer_address_address_b:
            "shipping_address[city]",
        ecield_event_customers_buyer_address_address_c:
            "shipping_address[address]",
        ecield_event_customers_buyer_address_address_d:
            "shipping_address[building]",
        ecield_event_customers_buyer_tel_fixed_number: "shipping_address[tel]",
        ecield_event_customers_buyer_tel_mobile_number:
            "shipping_address[sub_tel]",
        ecield_event_customers_buyer_pc_account:
            specialConstant.eggCartFrontUserEmailName,
        ecield_event_customers_buyer_mobile_account: "user[sub_email]"
    },
    tamagoUserTelId: [
        "shipping_address[tel1]",
        "shipping_address[tel2]",
        "shipping_address[tel3]"
    ],
    tamagoUserSubTelId: [
        "shipping_address[sub_tel1]",
        "shipping_address[sub_tel2]",
        "shipping_address[sub_tel3]"
    ],
    tamagoUserBirthdayId: [
        "user[birthday_1i]",
        "user[birthday_2i]",
        "user[birthday_3i]"
    ],
    tamagoDeliveryTelId: [
        "order[delivery_tel1]",
        "order[delivery_tel2]",
        "order[delivery_tel3]"
    ],
    tamagoDeliverySubTelId: [
        "order[sub_delivery_tel1]",
        "order[sub_delivery_tel2]",
        "order[sub_delivery_tel3]"
    ],
    tamagoLpUserTelId: [
        "shipping_address[tel1]",
        "shipping_address[tel2]",
        "shipping_address[tel3]"
    ],
    tamagoLpUserSubTelId: [
        "shipping_address[sub_tel1]",
        "shipping_address[sub_tel2]",
        "shipping_address[sub_tel3]"
    ],
    tamagoLpUserBirthdayId: [
        "user[birthday_1i]",
        "user[birthday_2i]",
        "user[birthday_3i]"
    ],
    tamagoFrontendUserBirthdayId: [
        "user[birthday_1i]",
        "user[birthday_2i]",
        "user[birthday_3i]"
    ],
    manualExaminationButtonIds: {
        subsc: "examination-button",
        egg: "address_table"
    }
};
