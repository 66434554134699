import { specialConstant } from "./special-constants";
import { standardEcieldKeys } from "./ecield_constants/standard-ecield-keys";
import { sampleGetCustomizedIdsResponse } from "./ecield_constants/sample-customized-ids";
import { tamagoConstants } from "./system-constants/tamago-default-constants";
import { ecieldDefaultIds } from "./ecield_constants/ecield-default-constants";
import { subscDefaultConstants } from "./system-constants/subsc-default-constants";
import { ecieldUrls } from "./ecield_constants/ecield-urls";
import { flagConstants } from "./ecield_constants/ecield-flag-constants";

export const EcieldConstants = {
    // 以下のURLは、送信された値と正確な応答を提供します。EcieldのURLがある場合、それが代わりに使用されます。
    ...ecieldUrls,

    /** 管理画面かどうかを判断する文字列 */
    ...flagConstants,

    eggCartLpUserEmailName: specialConstant.eggCartLpUserEmailName,
    eggCartFrontUserEmailName: specialConstant.eggCartFrontUserEmailName,
    eggCartFrontUserEmailId: specialConstant.eggCartFrontUserEmailId,
    eggCartManagementShippingTel: specialConstant.eggCartManagementShippingTel,
    eggCartManagementShippingSubTel:
        specialConstant.eggCartManagementShippingSubTel,
    eggCartManagementDeliveryTel: specialConstant.eggCartManagementDeliveryTel,
    eggCartManagementDeliverySubTel:
        specialConstant.eggCartManagementDeliverySubTel,
    eggCartManagementUserBirthday:
        specialConstant.eggCartManagementUserBirthday,
    eggCartLpShippingTel: specialConstant.eggCartLpShippingTel,
    eggCartLpShippingSubTel: specialConstant.eggCartLpShippingSubTel,
    eggCartLpUserBirthday: specialConstant.eggCartLpUserBirthday,
    eggCartLpUserEmail: specialConstant.eggCartLpUserEmail,
    eggCartFrontUserBirthday: specialConstant.eggCartFrontUserBirthday,

    eggCartLAddressDeliveryCheckboxId: "address_id_0",
    eggCartSoulLpConfirmAlwaysDisplaySubmitButtonId: "submit_order_confirm",
    eggCartSoulLpConfirmDirectSubmitButtonName: "commit",
    eggCartSoulLpConfirmDisplayBelowSubmitButtonId: "submit_order_form",

    // 固定されたシステム内で指定されたID値
    standardEcieldKeys: standardEcieldKeys.standardEcieldKeys,
    deliveryEcieldKeys: standardEcieldKeys.deliveryEcieldKeys,
    itemEcieldKeys: standardEcieldKeys.itemEcieldKeys,
    shopMainKeys: standardEcieldKeys.shopMainKeys,

    // 開発のため
    sampleGetCustomizeIdsResponse: sampleGetCustomizedIdsResponse.ids,

    defaultTamagoNames: tamagoConstants.defaultTamagoNames,
    defaultTamagoLpNames: tamagoConstants.defaultTamagoLpNames,
    defaultTamagoFrontendNames: tamagoConstants.defaultTamagoFrontendNames,

    // ショップのカスタマイズされたID値のサンプル。現在、APIは動作していないため、これはサンプルです。
    defaultIds: ecieldDefaultIds.ids,

    SubscLPDefaultIds: subscDefaultConstants.SubscLPDefaultIds,

    // Examinationデータ内で検索を実行するためのパス
    validateValuePaths: ecieldDefaultIds.validateValuePaths,

    tamagoUserTelId: tamagoConstants.tamagoUserTelId,
    tamagoUserSubTelId: tamagoConstants.tamagoUserSubTelId,
    tamagoUserBirthdayId: tamagoConstants.tamagoUserBirthdayId,
    tamagoDeliveryTelId: tamagoConstants.tamagoDeliveryTelId,
    tamagoDeliverySubTelId: tamagoConstants.tamagoDeliverySubTelId,
    tamagoLpUserTelId: tamagoConstants.tamagoLpUserTelId,
    tamagoLpUserSubTelId: tamagoConstants.tamagoLpUserSubTelId,
    tamagoLpUserBirthdayId: tamagoConstants.tamagoLpUserBirthdayId,
    tamagoFrontendUserBirthdayId: tamagoConstants.tamagoFrontendUserBirthdayId,
    tamagoCustomerBuyerId: "shop_event_customers_buyer_id",
    manualExaminationButtonIds: {
        subsc: "examination-button",
        egg: "address_table"
    }
};

/**
 * ECieldが持つ管理用のkeyと日本語名の対応づけ
 * keyを縛るため、分割して記述
 */
export const standardEcieldIdFieldTranslations: {
    [K in (typeof EcieldConstants.standardEcieldKeys)[number]]?: string;
} = {
    ecield_event_customers_buyer_birth_day: "誕生日",
    ecield_event_customers_buyer_sex: "性別",
    ecield_event_customers_buyer_name_first_name: "名",
    ecield_event_customers_buyer_name_last_name: "姓",
    ecield_event_customers_buyer_name_first_name_kana: "名（カナ）",
    ecield_event_customers_buyer_name_last_name_kana: "姓（カナ）",
    ecield_event_customers_buyer_address_zipcode: "郵便番号",
    ecield_event_customers_buyer_address_address_a: "住所（都道府県）",
    ecield_event_customers_buyer_address_address_b: "住所（市区町村）",
    ecield_event_customers_buyer_address_address_c: "住所（丁目番地）",
    ecield_event_customers_buyer_address_address_d: "住所（以降住所）",
    ecield_event_customers_buyer_tel_fixed_number: "電話番号",
    ecield_event_customers_buyer_tel_mobile_number: "電話番号",
    ecield_event_customers_buyer_pc_account: "PCメールアカウント",
    ecield_event_customers_buyer_pc_domain: "PCメールドメイン",
    ecield_event_customers_buyer_mobile_account: "モバイルメールアカウント",
    ecield_event_customers_buyer_mobile_domain: "モバイルメールドメイン"
};

/**
 * たまご管理画面での必須項目
 */
export const eggCartManagementRequiredIds = [
    EcieldConstants.defaultTamagoNames
        .ecield_event_customers_buyer_name_first_name,
    EcieldConstants.defaultTamagoNames
        .ecield_event_customers_buyer_name_last_name,
    EcieldConstants.defaultTamagoNames
        .ecield_event_customers_buyer_address_address_b,
    EcieldConstants.defaultTamagoNames
        .ecield_event_customers_buyer_address_address_c,
    EcieldConstants.defaultTamagoNames
        .ecield_event_customers_buyer_tel_fixed_number
];

export const subscManagementRequiredIds =
    subscDefaultConstants.subscManagementRequiredIds;
