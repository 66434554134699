import { hashObject } from "../../lib/utils/hashObject";
import { EcieldResult } from "../types";
import { isAxiosError } from "axios";
import { EcieldConstants } from "../constants";
import {
    Customers,
    ExaminationDto,
    ExaminationResponse
} from "types/class/dto/examination.dto";
import { sendGetRequest } from "./";

/**
 * Deeply merges two objects by recursively merging nested objects.
 * Non-object fields are overwritten from obj1 to obj2.
 * @param {Record<string, unknown>} obj1 - The first object to merge.
 * @param {Record<string, unknown>} obj2 - The second object to merge into.
 * @return {Record<string, unknown>} The merged object.
 */
function deepMergeObjects(
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>
): Record<string, unknown> {
    Object.entries(obj1).forEach(([key, value]) => {
        if (
            obj2.hasOwnProperty(key) &&
            typeof value === "object" &&
            value !== null &&
            typeof obj2[key] === "object"
        ) {
            obj2[key] = deepMergeObjects(
                value as Record<string, unknown>,
                obj2[key] as Record<string, unknown>
            );
        } else {
            obj2[key] = value;
        }
    });
    return obj2;
}

/**
 * 引数のオブジェクトを再起的にソートする（Unicode コードポイント順）
 * @param obj ソートするオブジェクト
 * @returns ソートされたオブジェクト
 */
function sortObject<T>(obj: T): T {
    if (Array.isArray(obj)) {
        return obj.map(sortObject) as unknown as T;
    } else if (obj && typeof obj === "object") {
        const sortedKeys = Object.keys(obj).sort() as Array<keyof typeof obj>;
        const result: Partial<T> = {};
        sortedKeys.forEach(key => {
            result[key] = sortObject(obj[key]);
        });
        return result as T;
    }
    return obj;
}

function findKeyByValue<T>(
    obj: Record<string, T>,
    valueToFind: T
): string | undefined {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
            return key.toString();
        }
    }
    return "";
}

function convertToHashObject<T extends object>(object: T): T {
    return hashObject(object) as T;
}

/**
 * 未知のエラーを受け取り、Axiosエラーである場合はそのメッセージを文字列で返します。
 * Axiosエラーでない場合、またはメッセージがない場合は、"Unknown error occurred."というデフォルトメッセージを返します。
 * @param error 検査するエラーオブジェクト
 * @returns エラーメッセージの文字列
 */
function transformAxiosErrorToMessage(error: unknown): string {
    if (isAxiosError(error) && error.message) {
        return `Error: ${error.message}`;
    }
    return "Unknown error occurred.";
}

function createErrorResponse<T>(errors: string[]): EcieldResult<T> {
    return {
        success: false,
        errors: errors
    };
}

function createSuccessResponse<T>(data: T): EcieldResult<T> {
    return {
        success: true,
        data: data
    };
}

/**
 * 与えられたオブジェクトから、指定されたパスの文字列に従って値を取得します。
 * パスはドットで区切られたキーの文字列で、ネストされたオブジェクト構造内の値にアクセスします。
 * 指定されたパスに該当する値が存在しない場合はundefinedを返します。
 * @param data 検索対象のオブジェクト
 * @param path 値を取得するためのパス
 * @returns パスに対応する値、またはundefined
 */
function getValueByPath<T>(data: T, path: string): T | undefined {
    const keys = path.split(".");
    let current: unknown = data;
    for (const key of keys) {
        if (current && typeof current === "object" && key in current) {
            current = (current as Record<string, unknown>)[key];
        } else {
            return undefined;
        }
    }
    return current as T;
}

// 'item' または 'delivery' のグループ数を特定
function getIndexesForMatchingKeys(keysToFind: string[]): Set<number> {
    const regex = /^(\w+)\[(\d+)\]$/;
    // eslint-disable-next-line quotes
    const elements = Array.from(document.querySelectorAll('[id$="]"]'));

    const groupIndices = new Set<number>();

    elements.forEach(element => {
        const match = element.id.match(regex);
        if (match && keysToFind.includes(match[1])) {
            const groupIndex = parseInt(match[2]);
            groupIndices.add(groupIndex);
        }
    });

    return groupIndices;
}

/**
 * 動作を許可するpathname一覧
 * @param cartSystem 利用しているカートシステム
 * @returns カートに対応するpathnameの配列
 */
function getDefaultPaths(cartSystem: string): Array<string> {
    if (cartSystem.includes("subsc")) {
        return window.React
            ? [
                  "/checkout/confirm",
                  "/checkout/landing_page_confirm",
                  "/sign_in"
              ]
            : [];
    }
    switch (cartSystem) {
        case "egg-cart":
            return [
                "/order/confirm",
                "/order/confirm_include",
                "/so/checkout/confirm"
            ];
        case "egg-cart-soul":
            return ["/order/confirm_include", "/so/checkout/confirm"];
        default:
            return [];
    }
}

/**
 * 指定されたミリ秒数だけ遅延
 * @param ms - 遅延させるミリ秒数
 */
const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 * スキップフラグがアクティブかどうかを判定
 * @returns スキップフラグがアクティブな場合はtrue、そうでない場合はfalse。
 */
const isSkipFlagActive = (): boolean => {
    const skipFlag: HTMLElement = document.getElementById(
        "skip-ecield-exminaiton-flag"
    );
    return (
        skipFlag instanceof HTMLElement && !!skipFlag.dataset.isSkipExamination
    );
};

/**
 * 最新の審査データを取得
 * @returns - 最新の審査データ。データが存在しない場合はnullを返します
 */
const getLatestExaminationData = async (): Promise<Customers | null> => {
    try {
        const response = await sendGetRequest<ExaminationDto>(
            EcieldConstants.ecieldExaminationLastDataUrl,
            {}
        );

        console.log("getLatestExaminationData", response);

        if (response.data) {
            return response.data.event.customers;
        }

        return null;
    } catch (error) {
        console.error(transformAxiosErrorToMessage(error));
    }
};

/**
 * 最新の審査結果を取得
 * @returns - 最新の審査結果。データが存在しない場合はnullを返します
 */
const getLatestExaminationResult =
    async (): Promise<ExaminationResponse | null> => {
        try {
            const response = await sendGetRequest<ExaminationResponse>(
                EcieldConstants.ecieldExaminationLatestResultUrl,
                {}
            );

            console.log("getLatestExaminationResult", response);

            if (response.data) {
                return response.data;
            }

            return null;
        } catch (error) {
            console.error(transformAxiosErrorToMessage(error));
        }
    };

export {
    deepMergeObjects,
    getValueByPath,
    getIndexesForMatchingKeys,
    transformAxiosErrorToMessage,
    createErrorResponse,
    createSuccessResponse,
    findKeyByValue,
    convertToHashObject,
    getDefaultPaths,
    delay,
    isSkipFlagActive,
    getLatestExaminationData,
    getLatestExaminationResult,
    sortObject
};
