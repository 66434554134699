class LocalStorageHandler<T> {
    private typeCheckFunction: (obj: unknown) => obj is T;

    constructor(
        private key: string,
        private defaultValue: T,
        typeCheckFunction: (obj: unknown) => obj is T
    ) {
        this.typeCheckFunction = typeCheckFunction;
    }

    /**
     * localStorageから値を取得する
     * @returns keyに対応した型安全が保証された値
     */
    getValue(): T {
        if (typeof window === "undefined") {
            return this.defaultValue;
        }

        try {
            const item = localStorage.getItem(this.key);
            if (item) {
                const parsedItem = JSON.parse(item) as unknown;
                if (this.typeCheckFunction(parsedItem)) {
                    return parsedItem;
                }
            }
        } catch (error) {
            console.error(
                `key: "${this.key}" を localStorage から読み取ることができませんでした`,
                error
            );
        }
        return this.defaultValue;
    }

    setValue(value: T): void {
        try {
            localStorage.setItem(this.key, JSON.stringify(value));
        } catch (error) {
            console.error(
                `key: "${this.key}" を localStorage に格納することができませんでした`,
                error
            );
        }
    }

    remove(): void {
        localStorage.removeItem(this.key);
    }
}

export type LocalStorageHandlers<S> = {
    [K in keyof S]: LocalStorageHandler<S[K]>;
};

export type LocalStorageSchema<S> = {
    [K in keyof S]: {
        defaultValue: S[K];
        typeCheckFunction: (obj: unknown) => obj is S[K];
    };
};

export const createLocalStorageHandlers = <S>(
    schema: LocalStorageSchema<S>
): LocalStorageHandlers<S> => {
    const handlers: LocalStorageHandlers<S> = {} as LocalStorageHandlers<S>;

    for (const key in schema) {
        const { defaultValue, typeCheckFunction } = schema[key];

        handlers[key as keyof S] = new LocalStorageHandler<S[typeof key]>(
            key,
            defaultValue,
            typeCheckFunction
        );
    }

    return handlers;
};
