import { ShopInfo, EcieldConfig } from "../types";

export const defaultConfigShopInfo: ShopInfo = {
    shop_id: null,
    cart_system: "",
    cart_system_id: null,
    domain: null
};

export const defaultConfigEcieldConfig: EcieldConfig = {
    isSubmitButtonDisabledUntilExamination: false,
    submitButtonIds: [],
    useScreen: "",
    systemScreenType: ""
};
