import { EcieldConstants } from "./../constants";
import {
    sendPostRequest,
    isTimeElapsedInMinutes,
    localStorageHandlers
} from "../utils";
import { redirectModal } from "./../components";
import { PostOrderType } from "../../types/type/dto/queue";

const thanksPagePathname = {
    subsc: "/checkout/complete",
    egg: "/order/thank_you_order",
    so: "/so/checkout/thanks"
};

const eggSoulLpThanksPagePathPattern = /^\/so\/lp\/[^\/]+\/thanks/;

const windowLocation = window.location;
const hostname = windowLocation.hostname;
let pathname = windowLocation.pathname;

const desiredPathsForStatusChange = Object.values(thanksPagePathname);

function postOrderUidToEcieldApi(cartSystem?: string) {
    // 魂かつ楽天の場合は画面遷移にリダイレクトページを使用しているので
    // thanksページに行かないと処理を開始しない
    if (
        cartSystem === "egg-cart-soul" &&
        !pathname.includes(thanksPagePathname.so) &&
        !eggSoulLpThanksPagePathPattern.test(pathname)
    ) {
        return;
    }

    const changeStatusData = localStorageHandlers.changeStatusData.getValue();

    const timestamp = changeStatusData.timestamp;
    const {
        title,
        message,
        customerId,
        isStatusChangeRequired,
        isModalDisplayRequired,
        eventId
    } = changeStatusData.data;

    // モーダル表示
    if (isModalDisplayRequired) {
        redirectModal(message, "", title, false);
        changeStatusData.data.isModalDisplayRequired = false;
        localStorageHandlers.changeStatusData.setValue(changeStatusData);
    }

    const isThanksPage =
        desiredPathsForStatusChange.includes(pathname) ||
        eggSoulLpThanksPagePathPattern.test(pathname);

    // データが10分未満に作成されかつ受注ステータス変更処理が必要な場合
    if (!isTimeElapsedInMinutes(timestamp, 10) && isThanksPage) {
        let orderUid: string = null;
        const isMobile = window.matchMedia("(max-width: 767px)").matches;

        if (pathname === thanksPagePathname.subsc && isMobile) {
            // ストア：window幅が767px以下の場合id参照、768以上の場合class参照
            orderUid = document.querySelector("#order-uid-string").textContent;
        } else if (pathname === thanksPagePathname.subsc) {
            // ストア
            orderUid =
                document.querySelector(".complete-uid").firstElementChild
                    .textContent;
        } else if (pathname === thanksPagePathname.egg) {
            // たまご
            // thanks_confirmクラスを持つ要素があればそれを取得しなければ、idがorder_noの要素をを取得
            const isExistThanksConfirm =
                document.querySelector(".thanks_confirm") != null;
            if (isExistThanksConfirm) {
                orderUid =
                    document.querySelector(".thanks_confirm").firstElementChild
                        .children[1].textContent;
            } else {
                orderUid =
                    document.querySelector("#order_no").lastChild.textContent;
            }
        } else {
            //魂
            // thanks_confirmクラスを持つdiv要素を取得
            const thanksConfirmDiv = document.querySelector(".thanks_confirm");

            // すべてのdtとdd要素を取得
            const dts = thanksConfirmDiv.querySelectorAll("dt");
            const dds = thanksConfirmDiv.querySelectorAll("dd");

            // dt要素の中で「ご注文番号」のテキストを持つ要素のインデックスを見つける
            let orderNumberIndex = -1;
            dts.forEach((dt, index) => {
                if (dt.textContent === "ご注文番号") {
                    orderNumberIndex = index;
                }
            });

            if (orderNumberIndex !== -1) {
                orderUid = dds[orderNumberIndex].textContent;
            }
        }

        const data: Omit<PostOrderType, "event_id"> & { event_id: string } = {
            order_uid: orderUid,
            hostname,
            customer_id: customerId,
            event_id: eventId
        };

        // 処理済みにする（後で消すけど、念には念を入れて）
        // POSTに成功していなくても、処理完了とする（でないと他店とorder_uidが被るかもしれないので）
        changeStatusData.data.isStatusChangeRequired = false;
        localStorageHandlers.changeStatusData.setValue(changeStatusData);

        // 出荷停止の場合は停止処理を、そうでない場合は注文UIDをECieldに飛ばす
        if (isStatusChangeRequired) {
            void sendPostRequest(EcieldConstants.ecieldChangeStatusUrl, data);
        } else {
            void sendPostRequest(EcieldConstants.ecieldSendOrderUidUrl, data);
        }

        // void sendPostRequest(EcieldConstants.ecieldChangeStatusUrl, data);

        // ローカルストレージのデータ消す
        localStorageHandlers.changeStatusData.remove();
    }
}

function statusChange() {
    const isUrlChangedToDesiredPath = (callback: { (): void; (): void }) => {
        if (sessionStorage.getItem("reloaded")) {
            if (
                desiredPathsForStatusChange.includes(window.location.pathname)
            ) {
                callback();
            }
        } else {
            sessionStorage.setItem("reloaded", "true");
        }

        const observer = new MutationObserver(() => {
            const newPath = window.location.pathname;
            if (newPath !== pathname) {
                pathname = newPath;
                if (desiredPathsForStatusChange.includes(newPath)) {
                    callback();
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });
    };
    isUrlChangedToDesiredPath(postOrderUidToEcieldApi);
}

export { statusChange, postOrderUidToEcieldApi };
