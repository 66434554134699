import { sendDeleteRequest, sendPostRequest } from "./axios-utils";
import { EcieldConstants } from "../constants";
import { v4 as uuidv4 } from "uuid";
import { JsonValue } from "@prisma/client/runtime/library";

/** ログ形式 */
type Log = {
    label?: string;
    obj?: JsonValue;
};

let currentTransactionId: string | null = null;

export const startLogTransaction = () => {
    currentTransactionId = uuidv4();
};

export const endLogTransaction = () => {
    currentTransactionId = null;
};

/**
 * ログ作成リクエストを送信する
 * @param log リクエストするログ
 * @param shopId 加盟店ID
 * @returns ログ作成レスポンス
 */
export const sendCreateLog = async (log: Log, shopId: string) => {
    if (!currentTransactionId) {
        console.error("No transaction ID found.");
        return;
    }

    try {
        const res = await sendPostRequest(EcieldConstants.ecieldCreateLogUrl, {
            ...log,
            transaction_id: currentTransactionId,
            user_id: shopId
        });
        console.log("Create Log sent successfully", res);
    } catch (error) {
        console.error("Error sending create log:", error);
    }
};

/**
 * ログ正常完了（削除）リクエストを送信する
 * @param shopId 加盟店ID
 * @returns ログ削除レスポンス
 */
export const sendCompleteLog = async (shopId: string) => {
    if (!currentTransactionId) {
        console.error("No transaction ID found.");
        return;
    }

    try {
        const res = await sendDeleteRequest(
            EcieldConstants.ecieldCompleteLogUrl,
            {
                transaction_id: currentTransactionId,
                user_id: shopId
            }
        );
        console.log("Complete Log sent successfully", res);
    } catch (error) {
        console.error("Error sending complete log:", error);
    }
};
