export const redirectModal = (
    message: string,
    redirectUrl: string,
    title: string,
    /** 自動リダイレクト処理を行うかどうか */
    shouldRedirect: boolean,
    /** リダイレクトまでの時間 */
    countdownSeconds: number = 10,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isTest: boolean = false
) => {
    // 表示要素の基本設定
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    overlay.style.zIndex = "1000";
    overlay.style.display = "flex";
    overlay.style.justifyContent = "center";
    overlay.style.alignItems = "center";

    // モーダル本体の設定
    const modalContent = document.createElement("div");
    modalContent.id = "examination-result-modal";
    modalContent.style.background = "white";
    modalContent.style.padding = "20px";
    modalContent.style.width = "auto"; // 幅を自動に
    modalContent.style.height = "auto"; // 高さを自動に
    modalContent.style.maxWidth = "500px";
    modalContent.style.boxShadow = "0px 0px 15px rgba(0, 0, 0, 0.2)";
    modalContent.style.borderRadius = "5px";
    modalContent.style.overflow = "auto";
    modalContent.style.display = "flex"; // Flexboxを使って
    modalContent.style.flexDirection = "column"; // 垂直方向に要素を配置
    modalContent.style.justifyContent = "center"; // 要素を中央に揃える
    modalContent.style.alignItems = "center"; // 要素を中央に揃える

    // タイトル
    const modalTitle = document.createElement("h3");
    modalTitle.textContent = title;
    modalTitle.style.marginBottom = "10px";
    modalContent.appendChild(modalTitle);

    // メッセージの表示エリア
    const modalMessage = document.createElement("p");
    modalMessage.textContent = message;
    modalContent.appendChild(modalMessage);

    // if (isTest) {
    //     const watermark = document.createElement("div");
    //     watermark.style.position = "absolute";
    //     watermark.style.top = "50%";
    //     watermark.style.left = "50%";
    //     watermark.style.transform = "translate(-50%, -50%)";
    //     watermark.style.zIndex = "999";
    //     watermark.style.opacity = "0.1";
    //     watermark.style.fontSize = "5rem";
    //     watermark.style.color = "black";
    //     watermark.style.fontWeight = "bold";
    //     watermark.style.textAlign = "center";
    //     watermark.textContent = "テスト";
    //     overlay.appendChild(watermark);
    // }

    // ボタンを中心に配置するためのWrapper
    const buttonWrapper = document.createElement("div");
    buttonWrapper.style.display = "flex";
    buttonWrapper.style.justifyContent = "center";
    buttonWrapper.style.alignItems = "center";
    buttonWrapper.style.height = "100%";

    // 閉じるボタンの追加
    const closeButton = document.createElement("button");
    closeButton.textContent = "閉じる";
    closeButton.style.cursor = "pointer";
    closeButton.style.padding = "10px 20px";
    closeButton.style.border = "none";
    closeButton.style.backgroundColor = "#3f51b5";
    closeButton.style.color = "white";
    closeButton.style.borderRadius = "5px";
    closeButton.style.transition = "background-color 0.3s";

    closeButton.onmouseover = function () {
        closeButton.style.backgroundColor = "#303f9f";
    };

    closeButton.onmouseout = function () {
        closeButton.style.backgroundColor = "#3f51b5";
    };

    buttonWrapper.appendChild(closeButton);
    modalContent.appendChild(buttonWrapper);

    overlay.appendChild(modalContent);
    document.body.appendChild(overlay);

    //CHANGE_STATUSの際に注文完了画面で、リダイレクト処理のないモーダルとして使うためshouldRedirectで管理
    if (shouldRedirect) {
        // カウントダウンメッセージ
        const countdownMessage = document.createElement("p");
        countdownMessage.textContent = `ページは ${countdownSeconds} 秒後、自動的にリダイレクトされます。`;
        countdownMessage.style.fontSize = "8px"; // フォントサイズを小さく
        countdownMessage.style.color = "#888"; // 文字色を灰色に
        modalContent.insertBefore(countdownMessage, buttonWrapper);

        let secondsRemaining = countdownSeconds;
        const countdownInterval = setInterval(() => {
            secondsRemaining--;
            countdownMessage.textContent = `ページは ${secondsRemaining} 秒後、自動的にリダイレクトされます。`;

            if (secondsRemaining <= 0) {
                clearInterval(countdownInterval);
                window.location.href = redirectUrl;
            }
        }, 1000);

        // 全体のクリックではなく、閉じるボタンのクリックだけでリダイレクト
        closeButton.addEventListener("click", () => {
            clearInterval(countdownInterval);
            window.location.href = redirectUrl;
        });

        closeButton.onclick = function () {
            window.location.href = redirectUrl;
        };
    } else {
        closeButton.onclick = function () {
            if (modalContent) {
                modalContent.style.display = "none";
                modalContent.parentNode?.removeChild(modalContent);
            }

            if (overlay) {
                overlay.remove();
            }
        };
    }

    return overlay;
};
